.about {
  padding-bottom: 42px;

  &__content {
    padding: 16px 0 32px;
    text-align: center;
  }

  @media (--mobileL) {
    .images-list__wrap {
       position: relative;
       max-width: 400px;
       margin: 0 auto;
     }
  }


  @media (--tabletS) {
    .images-list__wrap {
      .card-image {
        width: 50%;

        &:nth-of-type(odd) {
          padding: 0 24px 0 0;
        }

        &:nth-of-type(even) {
          padding: 0 0 0 24px;
        }
      }
    }
  }

  @media (--tablet) {
    padding-bottom: 46px;

    &__content {
      lost-column: 8/12;
      lost-offset: 2/12;
      padding: 32px 0 64px;
    }

    &__projects {
      .button {
        &__arrow {
          display: none;
        }
      }
    }

    .images-list__wrap {
      max-width: 100%;

      .card-image {
        width: 25%;

        &:nth-of-type(n) {
          padding: 0 24px;
        }

        &:nth-last-of-type {
          padding: 0 0 0 24px;
        }
      }
    }
  }

  @media (--laptop) {
    &__projects {
      .button {
        min-width: 156px;
      }
    }
  }
}