dfn, cite, i {
	font-style: italic;
}

em {
	color: $color-secondary;
}

strong, b {
	font-weight: 700;
}

blockquote, q {
	position: relative;
	margin: 20px 0;
	padding: 6px 0 6px 22px; // 16 padding + border width 6px
	display: block;
	font-weight: 700;

	p {
		font-size: 16px;
		font-weight: 400;
		line-height: 25px;
	}

	&:before,
	&:after {
		content: "";
	}

	&:before {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 6px;
		display: block;
		border-radius: 44px;
		background-color: $color-primary;
	}
}

address {
	margin: 0 0 1.5em;
}

pre {
	font-size: 90%;
	margin-bottom: 1.6em;
	max-width: 100%;
	overflow: auto;
	padding: 1.6em;
}

code, kbd, tt, var {
	font-size: 90%;
}

abbr, acronym {
	border-bottom: 1px dotted $color-primary;
	cursor: help;
}

mark, ins {
	background: $color-primary;
	color: $color-white;
	text-decoration: none;
}


big {
	font-size: 125%;
}

sub, sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sup {

	top: -0.5em;
}

sub {
	bottom: -0.25em;
}

.embedwrapper {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 30px;
	height: 0;
	width: 100%;
	overflow: hidden;
	background: gray;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

@media (--laptop) {
	blockquote,
	q,
	address {
		font-size: 22px;
		line-height: 32px;
	}
}