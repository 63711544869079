.collect-donations {
  &__title {
    margin-bottom: 32px;
    color: $color-primary;
    font-family: $font-secondary;
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
  }

  &__item {
    text-align: center;

    & + .collect-donations__item {
      margin-top: 18px;
    }
  }

  &__icon {
    margin-bottom: 10px;

    img {
      display: inline-block;
    }
  }

  &__subtitle {
    color: $color-stone;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    text-transform: uppercase;
  }

  @media (--tablet) {
    &__title {
      margin-bottom: 48px;
      font-size: 40px;
      line-height: 48px;
    }

    &__list {
      display: flex;
      justify-content: space-between;
    }

    &__item {
      padding: 0 16px;
      flex: 1;

      & + .collect-donations__item {
        margin-top: 0;
      }
    }

    &__icon {
      margin-bottom: 28px;
    }

    &__subtitle {
      font-size: 16px;
    }
  }
}