ul, ol {
	position: relative;
	margin: 16px 0 16px 24px;
	list-style: none;

	li {
		position: relative;
		color: $color-default;
		font-size: 14px;
		line-height: 24px;

		&:before {
			position: absolute;
			left: -24px;
		}

		&:last-of-type {
			margin-bottom: 0;
		}

		&+ li {
			margin-top: 8px;
		}
	}

	ol {
		margin: 8px 0 8px 24px;

		li {
			&:before {
				left: -24px;
			}
		}
	}
}

ul,
ol > ul {
	li {
		&:before {
			position: absolute;
			top: 8px;
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background-color: $color-sand;
			content: "";
		}
	}
}

ol,
ul > ol {
	counter-reset: section; /* Creates a new instance of the section counter with each ol element */
	list-style-type: none;

	li {
		&:before {
			counter-increment: section; /* Increments only this instance of the section counter */
			content: counters(section, ".") "."; /* Combines the values of all instances of the section counter, separated by a period */
			width: 16px;
			font-weight: 600;
			color: $color-sand;
		}
	}
}

li > ul,
li > ol {
	margin-bottom: 0;
}

li > ul {
	margin-left: 34px;
}

li > ol {
	margin-left: 34px;

	li:before {
		left: -24px;
	}
}

dt, dd {
	color: $color-primary;
	line-height: 24px;
}

dt {
	padding-bottom: 8px;
	font-size: 18px;
}

dd {
	padding-bottom: 16px;
	font-size: 16px;
}

@media (--laptop) {
	ul, ol {
		li {
			font-size: 16px;
		}
	}
}