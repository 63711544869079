.wrapper {
  @include container;
}

.wrapper-inner {
  @include inner-container;
}

a {
  text-decoration: none;
  transition: color 0.2s linear;
}

img {
  max-width: 100%;
}

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

/*fixed footer by flex*/
body {
  min-height: 100vh;
  display: flex;
  font-family: $font-primary;
  flex-direction: column;

  &.is-opened {
    &:after {
      @include absolute-position;
      position: fixed;
      z-index: 99;
      background-color: $color-black;
      opacity: 0.5;
      content: "";
    }
  }
}

.site-content {
  flex: 1;
}


/* global page title style */
.title {
  padding: 40px 24px 16px;
  background-image: url("assets/img/svg/bg-pattern.svg");
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  color: $color-primary;
  font-family: $font-secondary;
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;

  @media (--tablet) {
    padding-top: 90px;
    font-size: 40px;
    line-height: 48px;
  }
}

/* Divede with silver border */
.divider {
  width: 100%;
  height: 1px;
  background-color: $color-silver;

  @media (--tablet) {
    @include container;
  }
}

/* Pages multiple blocks before footer */
.page {
  &--default {
    padding-bottom: 42px;
  }

  &__collect-donations {
    padding-bottom: 16px;
  }

  &__form {
    position: relative;
    margin: 0 auto;
    padding-top: 34px;
    max-width: 466px;
    overflow: hidden;
  }

  &__form {
    padding-top: 34px;
  }

  &__content {
    padding-top: 24px;
  }

  @media (--tablet) {
    &--default {
      padding-bottom: 46px;
    }

    &__collect-donations {
      padding-bottom: 36px;
    }

    &__form {
      padding-top: 40px;
    }

    &__cta {
      padding-top: 52px;
      overflow: hidden;

      &-wrap {
        lost-column: 10/12;
        lost-offset: 1/12;
      }
    }

    &__content {
      padding-top: 32px;
    }
  }
}

/* custom-select class styles for selectric override */
.custom-select {
  .selectric-wrapper {
    z-index: 1;

    .selectric {
      border-radius: 3px;
    }

    .button {
      display: none;
    }

    .label {
      margin: 0 10px;
    }

    .selectric-items {
      ul {
        margin-bottom: 0;
      }

      li {
        width: 100%;

        &:first-of-type {
          display: none;
        }
      }
    }
  }
}