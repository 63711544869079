@charset "UTF-8";
/*
 * Theme Name: Friends
 * Description: Friends theme
 * Author: Turn.lv
 * Text Domain: friends
 * Domain Path: /languages
*/

@import "reset";
@import "variables";
@import "fonts";
@import "grid-settings";
@import "mixins";
@import "shame";

@import "wyswyg/_elements";
@import "plugins/custom-give";

@import "../../views/not-found";
@import "../../views/pages/about";
@import "../../views/pages/authors";
@import "../../views/pages/contributor";
@import "../../views/pages/home";
@import "../../views/pages/single-project";
@import "../../views/pages/stipendiaries";
@import "../../views/pages/stipendiary";

@import "../../views/partials/00-atoms/buttons/button";

@import "../../views/partials/01-molecules/card-person/card-person";
@import "../../views/partials/01-molecules/card-image/card-image";
@import "../../views/partials/01-molecules/collect-donations/collect-donations";
@import "../../views/partials/01-molecules/cta-build/cta-build";
@import "../../views/partials/01-molecules/cta-donate/cta-donate";

@import "../../views/partials/02-organisms/header/header";
@import "../../views/partials/02-organisms/footer/footer";
@import "../../views/partials/02-organisms/images-list/images-list";

/* fixes for specific browser bugs */
@import "browser-fix";