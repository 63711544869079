.stipendiary {
  padding: 16px 0 42px;

  &__profile {
    display: flex;
    align-items: center;
  }

  &__image {
    position: relative;
    width: 150px;
    height: 150px;
    overflow: hidden;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      transform: translate(-50%,-50%);
    }
  }

  &__details {
    padding-left: 24px;
  }

  &__name {
    color: $color-primary;
    font-family: $font-secondary;
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
  }

  &__faculty {
    color: $color-stone;
    font-size: 14px;
    line-height: 24px;
  }

  &__desc {
    margin-top: 8px;
  }

  &__info {
    margin-top: 16px;

    &-details {
      display: none;
    }
  }

  &__scholarship {
    margin-top: 24px;
    color: $color-default;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }

  &__year {
    margin-top: 8px;

    &.wyswyg {
      ul,
      ol {
        margin: 0 0 0 24px;
      }
    }
  }

  &__video {
    margin-top: 48px;

    &-image {
      img {
        width: 100%;
        display: block;
      }
    }

    &-frame {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;
      cursor: pointer;

      & + .single-members__video-frame {
        margin-top: 24px;
      }

      img {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        cursor: pointer;
        transform: translateY(-50%);
      }

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .play-button {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 80px;
        height: 80px;
        display: block;
        background-image: url("assets/img/svg/icon__play.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        transform: translate(-50%,-50%);
      }
    }
  }

  &__donations {
    padding-top: 74px;
  }

  @media (--tablet) {
    padding: 90px 0 46px;

    &__wrap {
      @include container;
    }

    &__content {
      lost-column: 12/12;
      overflow: hidden;
    }

    &__profile {
      lost-column: 4/12;
      lost-offset: 1/12;
    }

    &__details {
      display: none;
    }

    &__name {
      font-size: 40px;
      line-height: 42px;
    }

    &__desc {
      margin-top: 16px;
    }

    &__info {
      lost-column: 7/12;
      margin-top: 0;

      &-details {
        display: block;
      }
    }

    &__image {
      width: 240px;
      height: 240px;
    }

    &__scholarship{
      font-size: 24px;
      line-height: 32px;
    }

    &__video {
      margin-top: 104px;
    }

    &__donations {
      padding-top: 96px;
    }
  }
}
