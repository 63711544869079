.stipendiaries {
  padding-bottom: 42px;

  &__content {
    padding-top: 24px;
  }

  @media (--mobileS) {
    &__people {
      display: flex;
      flex-wrap: wrap;

      .card-person {
        margin-bottom: 16px;
        width: 50%;

        &:nth-of-type(odd) {
          padding-right: 12px;
        }

        &:nth-of-type(even) {
          padding-left: 12px;
        }
      }
    }
  }

  @media (--tablet) {
    padding-bottom: 48px;

    &__content {
      padding-top: 50px;
    }

    &__people {
      .card-person {
        margin-bottom: 54px;
        width: 33%;

        &:nth-of-type(odd),
        &:nth-of-type(even) {
          padding: 0;
        }

        &:nth-of-type(n) {
          padding: 0 24px;
        }
      }
    }
  }
}