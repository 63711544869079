.wyswyg {
	overflow: hidden;
	color: $color-default;

	hr {
		background-color: $color-default;
		border: 0;
		height: 1px;
		margin-bottom: 1.5em;
	}


	img {
		height: auto; /* Make sure images are scaled correctly. */
		max-width: 100%; /* Adhere to container width. */
	}

	figure {
		margin: 1em 0; /* Extra wide images within figure tags don't overflow the content area. */
	}

	p,
	span {
		font-size: 16px;
		color: $color-default;
		line-height: 24px;
	}

	p {
		&+ p,
		&+ img {
			margin-top: 8px;
		}
	}

	a {
		color: $color-primary;
		text-decoration: underline;
	}

	@media (--laptop) {
		p {
			&+ p,
			&+ img {
				margin-top: 16px;
			}
		}
	}

	@import "copy";

	@import "lists";

	@import "tables";

	@import "headings";
}