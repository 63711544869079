.button {
  position: relative;
  padding: 12px 30px;
  min-width: 240px;
  border: none;
  border-radius: 33px;
  overflow: hidden;
  display: inline-block;
  background-color: transparent;
  color: $color-white;
  font-size: 14px;
  letter-spacing: 2px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;

  &:before,
  &:after {
    @include absolute-position;
    z-index: -1;
    width: 100%;
    border-radius: 33px;
    content: "";
    transition: all 0.3s;
  }

  &:before {
    z-index: -1;
    background-color: $color-sand;
    transform: translateX(-100%);
  }

  &:after {
    z-index: -2;
    background-color: $color-secondary;
  }

  &:hover,
  &:active {
    background-color: transparent;

    &:before {
      transform: translateX(0%);
    }
  }

  &--tiny {
    padding: 8px;
    min-width: 170px;
    font-size: 10px;
    font-weight: 700;
  }

  &--donate {
    padding: 12px 24px;
    min-width: 160px;
    font-size: 18px;
    font-weight: 700;

    &:before {
      background-color: $color-secondary;
    }

    &:after {
      background-color: $color-sand;
    }
  }

  &__arrow {
    position: relative;
    top: 2px;

    svg {
      width: 10px;
      height: 10px;
    }
  }
}