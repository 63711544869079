.contributor {
  padding: 16px 0 40px;

  &__title {
    color: $color-primary;
    font-family: $font-secondary;
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;

    &--desktop {
      display: none;
    }
  }

  &__image {
    display: flex;
    align-items: flex-end;

    &-box {
      padding-right: 16px;
      flex: 1;
    }

    .contributor__title--mobile {
      flex: 1;
    }
  }

  &__more {
    margin-top: 24px;
    padding-bottom: 64px;
    overflow: hidden;

    &-details {
      position: relative;

      &:before {
        position: absolute;
        top: 0;
        left: 50%;
        width: 200%;
        height: 1px;
        background-color: $color-silver;
        content: '';
        transform: translateX(-50%);
      }
    }
  }

  &__col {
    padding-top: 24px;
  }

  &__stipendy,
  &__projects {
    padding-top: 16px;
  }

  &__info {
    padding-top: 16px;
  }

  @media (--tablet) {
    padding: 92px 0 48px;

    &__title {
      &--mobile {
        display: none;
      }

      &--desktop {
        display: block;
        font-size: 40px;
        line-height: 48px;
      }

      &--detail {
        font-size: 30px;
        line-height: 40px;
      }
    }

    &__head {
      lost-column: 12/12;
      overflow: hidden;
    }

    &__image,
    &__info {
      lost-column: 6/12;
      padding-top: 0;
    }

    &__more {
      margin-top: 40px;

      &-details {
        lost-column: 12/12;
        overflow: hidden;

        &:before {
          width: 100%;
        }
      }
    }

    &__col {
      lost-column: 6/12;
      padding-top: 32px;
    }
  }
}