.footer {
  background-color: $color-primary;

  &__content {
    position: relative;
    padding: 28px 0 38px;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 50px;
      height: 100px;
      display: block;
      background-image: url("assets/img/footer-logo.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      content: "";
    }
  }

  &__title {
    margin-left: 54px;
    color: $color-white;
    font-size: 14px;
    line-height: 24px;
    text-transform: uppercase;

    span {
      display: block;
      font-weight: 600;
    }
  }

  &__list {
    margin-top: 40px;
  }

  &__item {
    position: relative;
    margin-left: 54px;
    min-height: 75px;
    display: flex;
    align-items: center;

    &-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &--phone {
      .footer__item-icon {
        left: -40px;
      }
    }

    &--email {
      .footer__item-icon {
        left: -52px;
      }
    }

    &--web {
      .footer__item-icon {
        left: -53px;
      }
    }

    a {
      color: $color-white;
      font-size: 16px;
      font-weight: 600;
      line-height: 32px;
    }

    & + .footer__item {
      margin-top: 16px;
    }
  }

  @media (--tablet) {
    &__list {
      display: flex;
      justify-content: space-between;
    }

    &__item {
      &--phone {
        .footer__item-content {
          min-height: 64px;
        }
      }

      & + .footer__item {
        margin-top: 0;
      }
    }
  }

  @media (--laptop) {
    &__content {
      &:before {
        width: 88px;
        height: 178px;
      }
    }

    &__title {
      font-size: 16px;

      span {
        display: inline-block;
      }
    }

    &__title,
    &__list {
      margin-left: 156px;
    }

    &__item {
      margin-left: 70px;

      &--phone {
        margin-left: 56px;
        letter-spacing: 2px;

        .footer__item-icon {
          left: -56px;
        }
      }

      &--email {
        .footer__item-icon {
          left: -68px;
        }
      }

      &--web {
        .footer__item-icon {
          left: -69px;
        }
      }
    }
  }
}