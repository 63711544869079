.header {
  position: relative;
  z-index: 100;
  overflow: hidden;
  background-color: $color-secondary;
  background-image: url("assets/img/lu-building.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &:before,
  &:after {
    @include absolute-position;
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    transform: scale(1);
    transition: transform 0.3s ease-out;
    //transition: background-size 0.3s ease-out;
  }

  &:before {
    background-image: url("assets/img/flag-us.png");
    background-position: left center;
  }

  &:after {
    background-image: url("assets/img/flag-lv.png");
    background-position: right center;
  }

  &--active {
    &:before,
    &:after {
      //background-size: cover;
      transform: scale(1.6);
    }

    .header {
      &__bg {
        opacity: 0.7;
      }

      &__lang-list {
        display: flex;
      }

      &__motto {
        display: none;
      }
    }
  }

  &__bg {
    @include absolute-position;
    z-index: 1;
    background-color: $color-secondary;
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }

  &__wrap {
    position: relative;
    z-index: 10;
    padding: 24px 32px;
  }

  &__content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo {
    display: inline-block;
    font-size: 0;
    line-height: 0;

    &-link {
      position: relative;
      z-index: 1;
      width: 84px;
      height: 60px;
      display: inline-block;
      font-size: 0;
      line-height: 0;
    }

    svg {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 84px;
      height: 60px;
    }
  }

  &__menus {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;

    .menu-item {
      a {
        color: $color-white;
        font-family: $font-secondary;
      }
    }
  }

  &__menu-list {
    .menu-item {
      font-size: 24px;
      line-height: 32px;

      a {
        position: relative;
        overflow: hidden;
        display: inline-block;

        &:after {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 1px;
          background-color: $color-white;
          content: '';
          transform: translateX(-100%);
          transition: transform 0.3s ease-out;
        }
      }

      &:hover,
      &.current-lang {
        a {
          &:after {
            transform: translateX(0);
          }
        }
      }
    }
  }

  &__lang-list {
    margin-right: 48px;
    display: flex;

    .menu-item {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      background-color: transparent;
      justify-content: center;
      transition: background-color 0.3s ease-out;

      a {
        font-size: 15px;
        line-height: 18px;
      }

      &:hover,
      &.current-lang {
        background-color: $color-white;

        a {
          color: $color-secondary;
        }
      }

      &.current-lang {
        pointer-events: none;
      }

      & + .menu-item {
        margin-left: 20px;
      }
    }
  }

  &__menu-list,
  &__lang-list {
    display: none;
  }

  &__burger {
    position: absolute;
    top: 50%;
    right: 0;
    width: 100%;
    height: 6px;
    border-radius: 2px;
    background-color: $color-white;
    opacity: 1;
    transform: translateY(-50%);
    transition: all .3s ease-in-out;

    &:before,
    &:after {
      position: absolute;
      width: 38px;
      height: 6px;
      border-radius: 2px;
      display: block;
      background-color: $color-white;
      content: "";
      transition: all .3s ease-in-out;
    }

    &:before {
      transform: translateY(12px);
    }

    &:after {
      transform: translateY(-12px);
    }

    &-box {
      position: absolute;
      top: 50%;
      width: 38px;
      height: 16px;
      display: inline-block;
      transform: translateY(-50%);
    }

    &-button {
      position: relative;
      width: 38px;
      height: 34px;
      cursor: pointer;
    }

    &--open {
      .header__burger {
        background-color: transparent;

        &:before {
          top: 0;
          transform: rotate(45deg) translateY(0);
        }

        &:after {
          bottom: 0;
          transform: rotate(-45deg) translateY(0);
        }
      }
    }
  }

  &__drop {
    position: relative;
    z-index: 100;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease-out;

    &--active {
      max-height: 1000px;
    }

    &-content {
      height: calc(100vh - 108px);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .menu-item {
      text-align: center;
      a {
        position: relative;
        padding: 4px 16px;
        overflow: hidden;
        display: inline-block;
        color: $color-white;
        font-family: $font-secondary;
        font-size: 40px;
        font-weight: 700;
        line-height: 44px;

        &:after {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 1px;
          background-color: $color-white;
          content: '';
          transform: translateX(-100%);
          transition: transform 0.3s ease-out;
        }
      }

      &:hover,
      &.current-lang {
        a {
          &:after {
            transform: translateX(0);
          }
        }
      }

      & + .menu-item {
        margin-top: 18px;
      }
    }
  }

  &__motto {
    padding: 100px 24px 174px;

    p {
      color: $color-white;
      font-family: $font-secondary;
      font-size: 40px;
      font-weight: 700;
      line-height: 46px;
      text-align: center;
    }
  }

  @media (--tablet) {

    &:before,
    &:after {
      @include absolute-position;
      background-size: contain;
    }

    &__wrap {
      @include container;
      padding: 28px 16px;
    }

    &__logo {
      &-link,
      svg {
        width: 124px;
        height: 80px;
      }
    }

    &__menu-list,
    &__lang-list {
      display: flex
    }

    &__menu-list {
      margin-right: 80px;

      .menu-item {
        & + .menu-item {
          margin-left: 42px;
        }
      }
    }

    &__lang-list {
      margin-right: 0;

      .menu-item {
        width: 30px;
        height: 30px;

        a {
          font-size: 20px;
          line-height: 24px;
        }
      }
    }

    &__burger-button {
      display: none;
    }

    &__drop {
      display: none;
    }

    &__motto {
      padding: 96px 32px 194px;

      p {
        font-size: 62px;
        line-height: 70px;
      }
    }
  }
}