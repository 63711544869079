$ctaDonateArrowSize: 40px;
.cta-donate {
  position: relative;
  margin-bottom: $ctaDonateArrowSize;
  padding: 24px 32px;
  border-radius: 25px 25px 25px 0;
  background-color: $color-snow;
  text-align: center;

  &:after {
    position: absolute;
    left: 0;
    bottom: -$ctaDonateArrowSize;
    z-index: -1;
    border-top: $ctaDonateArrowSize solid transparent;
    border-bottom: $ctaDonateArrowSize solid transparent;
    border-left: 70px solid $color-snow;
    content: '';
  }

  &__content {
    position: relative;
    z-index: 1;
  }

  &__title {
    color: $color-stone;
    font-family: $font-secondary;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 2px;
    line-height: 40px;
  }

  &__btn {
    margin-top: 16px;
  }

  @media (--tablet) {
    padding: 28px 32px;

    &__content {
      margin: 0 auto;
      max-width: 520px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__title {
      font-size: 30px;
      line-height: 40px;
    }

    &__btn {
      margin-top: 0;
    }
  }
}