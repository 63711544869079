/*
 * Theme Name: Friends
 * Description: Friends theme
 * Author: Turn.lv
 * Text Domain: friends
 * Domain Path: /languages
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

@font-face {
	font-family: 'Open Sans';
	src: url("assets/fonts/openSans/OpenSans-Regular.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url("assets/fonts/openSans/OpenSans-Italic.woff") format("woff");
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Open Sans';
	src: url("assets/fonts/openSans/OpenSans-Light.woff") format("woff");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url("assets/fonts/openSans/OpenSans-SemiBold.woff") format("woff");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url("assets/fonts/openSans/OpenSans-Bold.woff") format("woff");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url("assets/fonts/openSans/OpenSans-ExtraBold.woff") format("woff");
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'BebasNeue';
	src: url("assets/fonts/bebasNeue/bebasneue-regular-webfont.woff2") format("woff2"), url("assets/fonts/bebasNeue/bebasneue-regular-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'BebasNeue';
	src: url("assets/fonts/bebasNeue/bebasneue-bold-webfont.woff2") format("woff2"), url("assets/fonts/bebasNeue/bebasneue-bold-webfont.woff") format("woff");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Play Fair';
	src: url("assets/fonts/playFair/PlayfairDisplay-Italic.woff") format("woff");
	font-weight: normal;
	font-style: italic;
}

@custom-media --mobileS (min-width: 320px);
@custom-media --mobileM (min-width: 375px);
@custom-media --mobileL (min-width: 465px);
@custom-media --mobileXL (min-width: 564px);
@custom-media --tabletS (min-width: 600px);
@custom-media --tablet (min-width: 768px);
@custom-media --tabletL (min-width: 840px);
@custom-media --laptop (min-width: 1060px);
@custom-media --fourK (min-width: 2560px);
@lost gutter 12px;
.wrapper {
	width: 94%;
	margin: 0 auto;
	transition: width .2s ease-in-out;
}

.wrapper::after {
	clear: both;
	content: "";
	display: table;
}

@media (--mobileL) {
	.wrapper {
		width: 96%;
		margin: 0 auto;
	}
	.wrapper::after {
		clear: both;
		content: "";
		display: table;
	}
}

@media (--tablet) {
	.wrapper {
		width: 96%;
		margin: 0 auto;
	}
	.wrapper::after {
		clear: both;
		content: "";
		display: table;
	}
}

@media (--laptop) {
	.wrapper {
		width: 1024px;
		margin: 0 auto;
	}
	.wrapper::after {
		clear: both;
		content: "";
		display: table;
	}
}

.wrapper-inner {
	width: 94%;
	margin: 0 auto;
	transition: width .2s ease-in-out;
}

.wrapper-inner::after {
	clear: both;
	content: "";
	display: table;
}

@media (--mobileL) {
	.wrapper-inner {
		width: 96%;
		margin: 0 auto;
	}
	.wrapper-inner::after {
		clear: both;
		content: "";
		display: table;
	}
}

@media (--tablet) {
	.wrapper-inner {
		width: 96%;
		margin: 0 auto;
	}
	.wrapper-inner::after {
		clear: both;
		content: "";
		display: table;
	}
}

@media (--laptop) {
	.wrapper-inner {
		width: 750px;
		margin: 0 auto;
	}
	.wrapper-inner::after {
		clear: both;
		content: "";
		display: table;
	}
}

a {
	text-decoration: none;
	transition: color 0.2s linear;
}

img {
	max-width: 100%;
}

html {
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
}

/*fixed footer by flex*/
body {
	min-height: 100vh;
	display: flex;
	font-family: "Open Sans", sans-serif;
	flex-direction: column;
}

body.is-opened:after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	position: fixed;
	z-index: 99;
	background-color: #000000;
	opacity: 0.5;
	content: "";
}

.site-content {
	flex: 1;
}

/* global page title style */
.title {
	padding: 40px 24px 16px;
	background-image: url("assets/img/svg/bg-pattern.svg");
	background-position: center;
	background-repeat: repeat;
	background-size: cover;
	color: #00529B;
	font-family: "BebasNeue", sans-serif;
	font-size: 28px;
	font-weight: 700;
	line-height: 32px;
	text-align: center;
}

@media (--tablet) {
	.title {
		padding-top: 90px;
		font-size: 40px;
		line-height: 48px;
	}
}

/* Divede with silver border */
.divider {
	width: 100%;
	height: 1px;
	background-color: #DDDDDD;
}

@media (--tablet) {
	.divider {
		width: 94%;
		margin: 0 auto;
		transition: width .2s ease-in-out;
	}
	.divider::after {
		clear: both;
		content: "";
		display: table;
	}
}

@media (--tablet) and (--mobileL) {
	.divider {
		width: 96%;
		margin: 0 auto;
	}
	.divider::after {
		clear: both;
		content: "";
		display: table;
	}
}

@media (--tablet) and (--tablet) {
	.divider {
		width: 96%;
		margin: 0 auto;
	}
	.divider::after {
		clear: both;
		content: "";
		display: table;
	}
}

@media (--tablet) and (--laptop) {
	.divider {
		width: 1024px;
		margin: 0 auto;
	}
	.divider::after {
		clear: both;
		content: "";
		display: table;
	}
}

/* Pages multiple blocks before footer */
.page--default {
	padding-bottom: 42px;
}

.page__collect-donations {
	padding-bottom: 16px;
}

.page__form {
	position: relative;
	margin: 0 auto;
	padding-top: 34px;
	max-width: 466px;
	overflow: hidden;
}

.page__form {
	padding-top: 34px;
}

.page__content {
	padding-top: 24px;
}

@media (--tablet) {
	.page--default {
		padding-bottom: 46px;
	}
	.page__collect-donations {
		padding-bottom: 36px;
	}
	.page__form {
		padding-top: 40px;
	}
	.page__cta {
		padding-top: 52px;
		overflow: hidden;
	}
	.page__cta-wrap {
		lost-column: 10/12;
		lost-offset: 1/12;
	}
	.page__content {
		padding-top: 32px;
	}
}

/* custom-select class styles for selectric override */
.custom-select .selectric-wrapper {
	z-index: 1;
}

.custom-select .selectric-wrapper .selectric {
	border-radius: 3px;
}

.custom-select .selectric-wrapper .button {
	display: none;
}

.custom-select .selectric-wrapper .label {
	margin: 0 10px;
}

.custom-select .selectric-wrapper .selectric-items ul {
	margin-bottom: 0;
}

.custom-select .selectric-wrapper .selectric-items li {
	width: 100%;
}

.custom-select .selectric-wrapper .selectric-items li:first-of-type {
	display: none;
}

.wyswyg {
	overflow: hidden;
	color: #2A2A2A;
}

.wyswyg hr {
	background-color: #2A2A2A;
	border: 0;
	height: 1px;
	margin-bottom: 1.5em;
}

.wyswyg img {
	height: auto;
	/* Make sure images are scaled correctly. */
	max-width: 100%;
	/* Adhere to container width. */
}

.wyswyg figure {
	margin: 1em 0;
	/* Extra wide images within figure tags don't overflow the content area. */
}

.wyswyg p,
.wyswyg span {
	font-size: 16px;
	color: #2A2A2A;
	line-height: 24px;
}

.wyswyg p + p,
.wyswyg p + img {
	margin-top: 8px;
}

.wyswyg a {
	color: #00529B;
	text-decoration: underline;
}

@media (--laptop) {
	.wyswyg p + p,
	.wyswyg p + img {
		margin-top: 16px;
	}
}

.wyswyg dfn, .wyswyg cite, .wyswyg i {
	font-style: italic;
}

.wyswyg em {
	color: #028ED5;
}

.wyswyg strong, .wyswyg b {
	font-weight: 700;
}

.wyswyg blockquote, .wyswyg q {
	position: relative;
	margin: 20px 0;
	padding: 6px 0 6px 22px;
	display: block;
	font-weight: 700;
}

.wyswyg blockquote p, .wyswyg q p {
	font-size: 16px;
	font-weight: 400;
	line-height: 25px;
}

.wyswyg blockquote:before, .wyswyg blockquote:after, .wyswyg q:before, .wyswyg q:after {
	content: "";
}

.wyswyg blockquote:before, .wyswyg q:before {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 6px;
	display: block;
	border-radius: 44px;
	background-color: #00529B;
}

.wyswyg address {
	margin: 0 0 1.5em;
}

.wyswyg pre {
	font-size: 90%;
	margin-bottom: 1.6em;
	max-width: 100%;
	overflow: auto;
	padding: 1.6em;
}

.wyswyg code, .wyswyg kbd, .wyswyg tt, .wyswyg var {
	font-size: 90%;
}

.wyswyg abbr, .wyswyg acronym {
	border-bottom: 1px dotted #00529B;
	cursor: help;
}

.wyswyg mark, .wyswyg ins {
	background: #00529B;
	color: #FFFFFF;
	text-decoration: none;
}

.wyswyg big {
	font-size: 125%;
}

.wyswyg sub, .wyswyg sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

.wyswyg sup {
	top: -0.5em;
}

.wyswyg sub {
	bottom: -0.25em;
}

.wyswyg .embedwrapper {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 30px;
	height: 0;
	width: 100%;
	overflow: hidden;
	background: gray;
}

.wyswyg .embedwrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

@media (--laptop) {
	.wyswyg blockquote,
	.wyswyg q,
	.wyswyg address {
		font-size: 22px;
		line-height: 32px;
	}
}

.wyswyg ul, .wyswyg ol {
	position: relative;
	margin: 16px 0 16px 24px;
	list-style: none;
}

.wyswyg ul li, .wyswyg ol li {
	position: relative;
	color: #2A2A2A;
	font-size: 14px;
	line-height: 24px;
}

.wyswyg ul li:before, .wyswyg ol li:before {
	position: absolute;
	left: -24px;
}

.wyswyg ul li:last-of-type, .wyswyg ol li:last-of-type {
	margin-bottom: 0;
}

.wyswyg ul li + li, .wyswyg ol li + li {
	margin-top: 8px;
}

.wyswyg ul ol, .wyswyg ol ol {
	margin: 8px 0 8px 24px;
}

.wyswyg ul ol li:before, .wyswyg ol ol li:before {
	left: -24px;
}

.wyswyg ul li:before,
.wyswyg ol > ul li:before {
	position: absolute;
	top: 8px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: #ED8C1D;
	content: "";
}

.wyswyg ol,
.wyswyg ul > ol {
	counter-reset: section;
	/* Creates a new instance of the section counter with each ol element */
	list-style-type: none;
}

.wyswyg ol li:before,
.wyswyg ul > ol li:before {
	counter-increment: section;
	/* Increments only this instance of the section counter */
	content: counters(section, ".") ".";
	/* Combines the values of all instances of the section counter, separated by a period */
	width: 16px;
	font-weight: 600;
	color: #ED8C1D;
}

.wyswyg li > ul,
.wyswyg li > ol {
	margin-bottom: 0;
}

.wyswyg li > ul {
	margin-left: 34px;
}

.wyswyg li > ol {
	margin-left: 34px;
}

.wyswyg li > ol li:before {
	left: -24px;
}

.wyswyg dt, .wyswyg dd {
	color: #00529B;
	line-height: 24px;
}

.wyswyg dt {
	padding-bottom: 8px;
	font-size: 18px;
}

.wyswyg dd {
	padding-bottom: 16px;
	font-size: 16px;
}

@media (--laptop) {
	.wyswyg ul li, .wyswyg ol li {
		font-size: 16px;
	}
}

.wyswyg .wyswyg__table {
	padding-top: 16px;
	margin-bottom: 24px;
	overflow-x: auto;
}

.wyswyg table {
	table-layout: fixed;
}

.wyswyg thead th {
	border-bottom: 3px solid #00529B;
}

.wyswyg thead th,
.wyswyg thead td,
.wyswyg tfoot th,
.wyswyg tfoot td {
	padding: 0 0 8px;
	color: #00529B;
	font-weight: 600;
	text-align: left;
}

.wyswyg tbody th,
.wyswyg tbody td {
	border-bottom: 1px solid #00529B;
}

.wyswyg tbody th + th,
.wyswyg tbody th + td,
.wyswyg tbody td + th,
.wyswyg tbody td + td {
	border-left: 1px solid #00529B;
}

.wyswyg tbody tr:last-of-type td {
	border-bottom: 3px solid #00529B;
}

.wyswyg th,
.wyswyg td {
	padding: 16px 16px 16px 0;
	min-width: 188px;
	font-size: 16px;
	color: #2A2A2A;
	line-height: 24px;
}

.wyswyg th + th,
.wyswyg th + td,
.wyswyg td + th,
.wyswyg td + td {
	padding: 16px 16px 16px 10px;
}

.wyswyg tr {
	background-color: transparent;
	transition: background-color 0.2s linear;
}

.wyswyg tr:hover {
	background-color: #f7f7f7;
}

@media (--laptop) {
	.wyswyg thead th,
	.wyswyg thead td,
	.wyswyg tfoot th,
	.wyswyg tfoot td {
		font-size: 22px;
	}
	.wyswyg th,
	.wyswyg td {
		padding: 26px 26px 26px 0;
		font-size: 20px;
		letter-spacing: 0.4px;
		line-height: 30px;
	}
	.wyswyg th + th,
	.wyswyg th + td,
	.wyswyg td + th,
	.wyswyg td + td {
		padding: 26px 26px 26px 22px;
	}
}

.wyswyg h1, .wyswyg h2, .wyswyg h3, .wyswyg h4, .wyswyg h5, .wyswyg h6 {
	font-family: "Open Sans", sans-serif;
	color: #4A4A4A;
}

.wyswyg h1, .wyswyg h2, .wyswyg h3 {
	text-transform: uppercase;
}

.wyswyg h1 {
	font-size: 41px;
	line-height: 48px;
}

.wyswyg h2 {
	font-size: 28px;
	line-height: 32px;
}

.wyswyg h3 {
	font-size: 14px;
	line-height: 24px;
}

.wyswyg h3, .wyswyg h4, .wyswyg h5, .wyswyg h6 {
	font-weight: 700;
}

.wyswyg h4, .wyswyg h5 {
	font-size: 18px;
	line-height: 24px;
}

.wyswyg h5 {
	font-family: "BebasNeue", sans-serif;
}

.wyswyg h5 + h4 {
	margin-top: 24px;
}

.wyswyg h6 {
	font-size: 14px;
	line-height: 20px;
}

.wyswyg div + h1, .wyswyg p + h1, .wyswyg ul + h1 {
	margin-top: 32px;
}

.wyswyg div + h2,
.wyswyg div + h3,
.wyswyg div + h4, .wyswyg p + h2,
.wyswyg p + h3,
.wyswyg p + h4, .wyswyg ul + h2,
.wyswyg ul + h3,
.wyswyg ul + h4 {
	margin-top: 16px;
}

.wyswyg div + h5, .wyswyg p + h5, .wyswyg ul + h5 {
	margin-top: 24px;
}

@media (--laptop) {
	.wyswyg h1 {
		font-size: 62px;
		line-height: 64px;
	}
	.wyswyg h2 {
		font-size: 40px;
		line-height: 48px;
	}
	.wyswyg h3 {
		font-size: 16px;
	}
	.wyswyg h4, .wyswyg h5 {
		font-size: 24px;
		line-height: 32px;
	}
	.wyswyg h6 {
		font-size: 16px;
	}
	.wyswyg div + h1, .wyswyg p + h1, .wyswyg ul + h1 {
		margin-top: 56px;
	}
	.wyswyg div + h2, .wyswyg p + h2, .wyswyg ul + h2 {
		margin-top: 16px;
	}
	.wyswyg div + h4, .wyswyg p + h4, .wyswyg ul + h4 {
		margin-top: 40px;
	}
}

.custom-give .give-form {
	text-align: center;
}

.custom-give .give-sum-text {
	margin-bottom: 16px;
	color: #4A4A4A;
	font-size: 14px;
	font-weight: 700;
	line-height: 24px;
	text-transform: uppercase;
}

.custom-give .give-form-title {
	margin-bottom: 16px;
	color: #4A4A4A;
	font-size: 14px;
	font-weight: 700;
	line-height: 24px;
	text-align: center;
	text-transform: uppercase;
}

.custom-give .give-goal-progress {
	margin-bottom: 32px;
	color: #8D8D8D;
	font-size: 14px;
	line-height: 16px;
}

.custom-give .give-goal-progress .raised {
	margin-bottom: 18px;
}

.custom-give .give-goal-progress .income {
	margin-right: 8px;
	color: #00529B;
	font-family: "BebasNeue", sans-serif;
	font-size: 30px;
	font-weight: 700;
	line-height: 40px;
}

.custom-give .give-donation-expenses {
	margin-bottom: 16px;
	display: none;
}

.custom-give .give_error,
.custom-give .give_success {
	margin: 15px;
}

.custom-give .give-btn {
	position: relative;
	padding: 16px 30px;
	min-width: 154px;
	border: none;
	border-radius: 33px;
	overflow: hidden;
	background-color: transparent;
	color: #FFFFFF;
	font-size: 18px;
	letter-spacing: 2px;
	line-height: 20px;
	text-transform: uppercase;
}

.custom-give .give-btn:before, .custom-give .give-btn:after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -1;
	width: 100%;
	border-radius: 33px;
	content: "";
	transition: all 0.3s;
}

.custom-give .give-btn:before {
	z-index: -1;
	background-color: #028ED5;
	transform: translateX(-100%);
}

.custom-give .give-btn:after {
	z-index: -2;
	background-color: #ED8C1D;
}

.custom-give .give-btn:hover, .custom-give .give-btn:active {
	background-color: transparent;
}

.custom-give .give-btn:hover:before, .custom-give .give-btn:active:before {
	transform: translateX(0%);
}

.custom-give form[id*="give-form"].give-display-modal .give-btn,
.custom-give form[id*="give-form"].give-display-reveal .give-btn,
.custom-give form[id*="give-form"] .give-btn-modal {
	margin: 0;
}

.custom-give form[id*="give-form"] .give-donation-amount {
	margin-bottom: 22px;
	display: inline-block;
}

.custom-give form[id*="give-form"] .give-donation-amount #give-amount {
	padding: 4px 12px;
	border-radius: 7px 0 0 7px;
}

.custom-give form[id*="give-form"] .give-donation-amount #give-amount:focus {
	box-shadow: 0 1px 6px 0 rgba(2, 142, 213, 0.32);
}

.custom-give form[id*="give-form"] .give-donation-amount #give-amount:focus + .give-currency-symbol.give-currency-position-after {
	box-shadow: 0 1px 6px 0 rgba(2, 142, 213, 0.32);
}

.custom-give form[id*="give-form"] .give-donation-amount #give-amount:focus + .give-currency-symbol.give-currency-position-after:before {
	display: block;
}

.custom-give form[id*="give-form"] .give-donation-amount .give-currency-symbol.give-currency-position-after {
	position: relative;
	padding: 4px 12px 4px 0;
	border-radius: 0 7px 7px 0;
}

.custom-give form[id*="give-form"] .give-donation-amount .give-currency-symbol.give-currency-position-after:before {
	position: absolute;
	top: 0;
	left: -6px;
	bottom: 0;
	display: none;
	width: 6px;
	background-color: #F6F6F6;
	content: '';
}

.custom-give form[id*="give-form"] .give-donation-amount #give-amount,
.custom-give form[id*="give-form"] .give-donation-amount .give-currency-symbol.give-currency-position-after {
	height: initial;
	border: none;
	background-color: #F6F6F6;
	color: #4A4A4A;
	font-family: "BebasNeue", sans-serif;
	font-size: 30px;
	font-weight: 700;
	line-height: 40px;
}

@media (--tablet) {
	.custom-give .give-form-title {
		font-size: 16px;
	}
	.custom-give .give-sum-text {
		font-size: 16px;
	}
}

.give-append-link {
	margin: 16px auto 0;
	max-width: 466px;
	text-align: center;
}

.give-append-link a {
	color: #8D8D8D;
	font-size: 14px;
	line-height: 20px;
	text-decoration: underline;
}

.give-append-link a:hover {
	color: #4A4A4A;
}

body .give_error,
body .give_success,
body .give_warning {
	margin: 15px 0 15px 15px;
}

/* give modal style */
.give-modal .mfp-close {
	font-size: 44px;
}

.give-modal .mfp-container .mfp-content {
	padding: 36px 10px 48px;
	width: 100%;
	max-width: 830px;
}

.give-modal form[id*="give-form"] #give_checkout_user_info legend,
.give-modal form[id*="give-form"] .give-payment-mode-label {
	border: none;
	color: #00529B;
	font-family: "BebasNeue", sans-serif;
	font-size: 26px;
	line-height: 32px;
	text-align: center;
}

.give-modal form[id*="give-form"] #give_purchase_form_wrap {
	padding-top: 24px;
}

.give-modal form[id*="give-form"] #give_checkout_user_info legend {
	margin-top: 0;
}

.give-modal form[id*="give-form"] #give-payment-mode-select .give-loading-text {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 10;
	height: 32px;
	width: 30px;
	font-size: 30px;
	transform: translateX(-50%, -50%);
}

.give-modal form[id*="give-form"] #give-payment-mode-select .give-loading-animation {
	height: 32px;
	width: 30px;
	font-size: 30px;
}

.give-modal form[id*="give-form"] #give-gateway-radio-list li {
	position: relative;
	margin-right: 0;
	width: 100%;
	display: block;
	background-color: #F6F6F6;
}

.give-modal form[id*="give-form"] #give-gateway-radio-list li + li {
	margin-top: 8px;
}

.give-modal form[id*="give-form"] #give-gateway-radio-list .give-gateway {
	position: absolute;
	top: 50%;
	left: 8px;
	z-index: -1;
	width: 10px;
	height: 10px;
	opacity: 0;
	transform: translateY(-50%);
}

.give-modal form[id*="give-form"] #give-gateway-radio-list .give-gateway:checked + .give-gateway-option:before {
	background-color: #ED8C1D;
}

.give-modal form[id*="give-form"] #give-gateway-radio-list .give-gateway-option {
	padding: 12px 14px 12px 48px;
	width: 100%;
	display: block;
	color: #4A4A4A;
	font-size: 14px;
	line-height: 24px;
	text-transform: uppercase;
}

.give-modal form[id*="give-form"] #give-gateway-radio-list .give-gateway-option:before {
	position: absolute;
	top: 50%;
	left: 8px;
	width: 25px;
	height: 25px;
	border: 2px solid #ED8C1D;
	border-radius: 50%;
	content: '';
	background-color: transparent;
	transform: translateY(-50%);
	transition: background-color 0.3s ease-out;
}

.give-modal form[id*="give-form"] .give-select-wrap .selectric .label {
	height: 42px;
	font-size: 16px;
	line-height: 42px;
}

.give-modal form[id*="give-form"] #give_checkout_user_info .form-row {
	position: relative;
}

.give-modal form[id*="give-form"] #give_checkout_user_info .form-row input {
	padding: 0 10px;
	height: 44px;
	border-radius: 3px;
	line-height: 44px;
}

.give-modal form[id*="give-form"] #give_checkout_user_info .give-label {
	opacity: 0;
	visibility: hidden;
	display: block;
	position: absolute;
	top: -12px;
	left: 0;
	font-size: 12px;
	font-weight: 700;
	line-height: 1;
	transition: all .2s ease-in-out;
	z-index: 1;
}

.give-modal form[id*="give-form"] #give-final-total-wrap .give-donation-total-label,
.give-modal form[id*="give-form"] #give-final-total-wrap .give-final-total-amount {
	width: 100%;
	height: initial;
	border: none;
	display: block;
	background-color: transparent;
	color: #4A4A4A;
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
	text-align: center;
	text-transform: uppercase;
}

.give-modal form[id*="give-form"] .give-submit-button-wrap {
	display: flex;
	justify-content: center;
}

.give-modal form[id*="give-form"] .give-submit-button-wrap .give-btn {
	position: relative;
	margin: 0 auto;
	padding: 16px 30px;
	min-width: 154px;
	border: none;
	border-radius: 33px;
	overflow: hidden;
	display: inline-block;
	background-color: #ED8C1D;
	color: #FFFFFF;
	font-size: 18px;
	letter-spacing: 2px;
	line-height: 20px;
	text-transform: uppercase;
	transition: all 0.3s;
}

.give-modal form[id*="give-form"] .give-submit-button-wrap .give-btn:hover, .give-modal form[id*="give-form"] .give-submit-button-wrap .give-btn:active {
	background-color: #028ED5;
}

@media (--mobileXL) {
	.give-modal form[id*="give-form"] #give_checkout_user_info .form-row input {
		font-size: 16px;
	}
}

@media (--tablet) {
	.give-modal .mfp-container .mfp-content {
		padding: 60px 96px 40px;
	}
	.give-modal form[id*="give-form"] #give_purchase_form_wrap {
		margin-top: 16px;
		border-top: 1px solid #DDDDDD;
	}
	.give-modal form[id*="give-form"] #give_checkout_user_info legend,
	.give-modal form[id*="give-form"] .give-payment-mode-label {
		font-size: 40px;
		line-height: 42px;
	}
	.give-modal form[id*="give-form"] #give-gateway-radio-list {
		display: flex;
		flex-wrap: wrap;
	}
	.give-modal form[id*="give-form"] #give-gateway-radio-list li {
		margin-bottom: 8px;
		width: 48%;
		width: calc(50% - 4px);
	}
	.give-modal form[id*="give-form"] #give-gateway-radio-list li:nth-of-type(even) {
		margin-left: 4px;
	}
	.give-modal form[id*="give-form"] #give-gateway-radio-list li:nth-of-type(odd) {
		margin-right: 4px;
	}
	.give-modal form[id*="give-form"] #give-gateway-radio-list li + li {
		margin-top: 0;
	}
	.give-modal form[id*="give-form"] #give-final-total-wrap {
		margin-top: 50px;
	}
	.give-modal form[id*="give-form"] .give-select-wrap {
		width: 48.5%;
		float: right;
	}
	.give-modal form[id*="give-form"] #give_checkout_user_info #give-email-wrap {
		width: 48.5%;
		float: left;
	}
}

.not-found__content {
	margin-top: 162px;
	text-align: center;
}

.not-found__error {
	color: #ED8C1D;
	font-size: 86px;
	font-weight: 900;
	line-height: 86px;
}

.not-found__title, .not-found__subtitle {
	color: #4A4A4A;
	font-size: 16px;
	line-height: 24px;
}

.not-found__title {
	margin-top: 24px;
	font-weight: 700;
	text-transform: uppercase;
}

.not-found__subtitle {
	margin-top: 16px;
}

.not-found__btn {
	margin-top: 24px;
}

@media (--tablet) {
	.not-found__content {
		lost-column: 6/12;
		lost-offset: 3/12;
		margin-top: 240px;
	}
	.not-found__error {
		font-size: 124px;
		line-height: 124px;
	}
	.not-found__title {
		margin-top: 32px;
		font-size: 22px;
		line-height: 32px;
	}
	.not-found__btn {
		margin-top: 32px;
	}
}

.about {
	padding-bottom: 42px;
}

.about__content {
	padding: 16px 0 32px;
	text-align: center;
}

@media (--mobileL) {
	.about .images-list__wrap {
		position: relative;
		max-width: 400px;
		margin: 0 auto;
	}
}

@media (--tabletS) {
	.about .images-list__wrap .card-image {
		width: 50%;
	}
	.about .images-list__wrap .card-image:nth-of-type(odd) {
		padding: 0 24px 0 0;
	}
	.about .images-list__wrap .card-image:nth-of-type(even) {
		padding: 0 0 0 24px;
	}
}

@media (--tablet) {
	.about {
		padding-bottom: 46px;
	}
	.about__content {
		lost-column: 8/12;
		lost-offset: 2/12;
		padding: 32px 0 64px;
	}
	.about__projects .button__arrow {
		display: none;
	}
	.about .images-list__wrap {
		max-width: 100%;
	}
	.about .images-list__wrap .card-image {
		width: 25%;
	}
	.about .images-list__wrap .card-image:nth-of-type(n) {
		padding: 0 24px;
	}
	.about .images-list__wrap .card-image:nth-last-of-type {
		padding: 0 0 0 24px;
	}
}

@media (--laptop) {
	.about__projects .button {
		min-width: 156px;
	}
}

.authors {
	padding-bottom: 58px;
}

.authors__qoute {
	padding-top: 32px;
}

.authors__qoute__wrap {
	overflow: hidden;
}

.authors__qoute-main, .authors__qoute-author {
	color: #4A4A4A;
	font-family: "Play Fair", sans-serif;
}

.authors__qoute-main {
	font-size: 20px;
	line-height: 32px;
	text-align: center;
}

.authors__qoute-author {
	position: relative;
	margin-top: 16px;
	padding: 0 12px;
	display: inline-block;
	float: right;
	font-size: 16px;
	line-height: 24px;
}

.authors__qoute-author:before, .authors__qoute-author:after {
	position: absolute;
	top: 50%;
	width: 1px;
	height: 50%;
	display: inline-block;
	background-color: #4A4A4A;
	content: '';
	transform: translateY(-50%) skewX(-25deg);
}

.authors__qoute-author:before {
	left: 0;
}

.authors__qoute-author:after {
	right: 0;
}

.authors__about {
	padding-top: 34px;
}

@media (--tablet) {
	.authors__wrap {
		lost-column: 8/12;
		lost-offset: 2/12;
	}
	.authors__author {
		text-align: center;
	}
}

.contributor {
	padding: 16px 0 40px;
}

.contributor__title {
	color: #00529B;
	font-family: "BebasNeue", sans-serif;
	font-size: 28px;
	font-weight: 700;
	line-height: 32px;
}

.contributor__title--desktop {
	display: none;
}

.contributor__image {
	display: flex;
	align-items: flex-end;
}

.contributor__image-box {
	padding-right: 16px;
	flex: 1;
}

.contributor__image .contributor__title--mobile {
	flex: 1;
}

.contributor__more {
	margin-top: 24px;
	padding-bottom: 64px;
	overflow: hidden;
}

.contributor__more-details {
	position: relative;
}

.contributor__more-details:before {
	position: absolute;
	top: 0;
	left: 50%;
	width: 200%;
	height: 1px;
	background-color: #DDDDDD;
	content: '';
	transform: translateX(-50%);
}

.contributor__col {
	padding-top: 24px;
}

.contributor__stipendy, .contributor__projects {
	padding-top: 16px;
}

.contributor__info {
	padding-top: 16px;
}

@media (--tablet) {
	.contributor {
		padding: 92px 0 48px;
	}
	.contributor__title--mobile {
		display: none;
	}
	.contributor__title--desktop {
		display: block;
		font-size: 40px;
		line-height: 48px;
	}
	.contributor__title--detail {
		font-size: 30px;
		line-height: 40px;
	}
	.contributor__head {
		lost-column: 12/12;
		overflow: hidden;
	}
	.contributor__image, .contributor__info {
		lost-column: 6/12;
		padding-top: 0;
	}
	.contributor__more {
		margin-top: 40px;
	}
	.contributor__more-details {
		lost-column: 12/12;
		overflow: hidden;
	}
	.contributor__more-details:before {
		width: 100%;
	}
	.contributor__col {
		lost-column: 6/12;
		padding-top: 32px;
	}
}

.home--content {
	padding: 28px 0 42px;
}

.home__done-title, .home__stipendiaries-title {
	color: #00529B;
	font-family: "BebasNeue", sans-serif;
	font-size: 28px;
	font-weight: 700;
	line-height: 32px;
	text-align: center;
}

.home__done {
	padding-bottom: 56px;
}

.home__done-desc {
	margin-top: 8px;
	color: #4A4A4A;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
}

.home__done-list {
	margin-top: 36px;
}

.home__done-item {
	text-align: center;
}

.home__done-item + .home__done-item {
	margin-top: 40px;
}

.home__done-number {
	position: relative;
	padding-top: 28px;
	width: 148px;
	height: 64px;
	display: inline-block;
	background-image: url("assets/img/lu-building.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	color: #00529B;
	font-size: 22px;
	font-weight: 700;
	text-align: center;
}

.home__done-fact {
	margin-top: 12px;
	color: #4A4A4A;
	font-size: 14px;
	font-weight: 700;
	line-height: 24px;
	text-transform: uppercase;
}

.home__banner {
	padding-bottom: 60px;
}

.home__board {
	margin-bottom: 60px;
	overflow: hidden;
}

.home__board-list .wrapper {
	width: 100%;
}

.home__board > .wrapper {
	position: relative;
}

.home__board > .wrapper:after {
	position: absolute;
	top: 60px;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -3;
	width: 100%;
	height: 100%;
	display: block;
	background-color: #FAFAFA;
	background-image: url("assets/img/svg/bg-pattern.svg");
	background-position: center;
	background-repeat: repeat;
	background-size: contain;
	content: '';
}

.home__board-link {
	background-color: #FFFFFF;
	text-align: center;
}

.home__board-link .button {
	z-index: 1;
}

.home__board .images-list {
	margin-bottom: 0;
}

.home__board .images-list__wrap:after {
	display: none;
}

.home__board .images-list__content .card-image {
	margin-bottom: 0;
}

.home__board .images-list__content .card-image__info {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.home__board .images-list__content .card-image__title {
	position: relative;
	margin-top: 0;
	padding: 8px 0 24px;
	flex: 1;
}

.home__board .images-list__content .card-image__title:after {
	position: absolute;
	top: 0;
	left: 50%;
	z-index: -1;
	width: 300%;
	height: 100%;
	display: block;
	background-color: #FFFFFF;
	content: '';
	transform: translateX(-50%);
}

.home__stipendiaries-box {
	margin-top: 16px;
	padding: 0 28px;
}

.home__stipendiaries-link {
	margin-top: 26px;
	text-align: center;
}

.home__stipendiaries-slider .slick-list {
	padding-top: 16px;
	border-right: 1px solid #028ED5;
	border-left: 1px solid #028ED5;
}

.home__stipendiaries-slider .slick-slide {
	padding: 0 12px;
}

.home__stipendiaries-slider .slick-arrow {
	position: absolute;
	top: 50%;
	margin: 0;
	padding: 0;
	width: 26px;
	height: 30px;
	border: none;
	background-color: transparent;
	background-image: url("assets/img/svg/icon__arrow-blue.svg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	cursor: pointer;
	font-size: 0;
	line-height: 0;
	outline: none;
}

.home__stipendiaries-slider .slick-arrow.slick-next {
	right: 0;
	transform: translate(28px, -50%) rotate(0deg);
}

.home__stipendiaries-slider .slick-arrow.slick-prev {
	left: 0;
	transform: translate(-28px, -50%) rotate(180deg);
}

.home__stipendiaries-nav {
	margin-top: 16px;
}

.home__stipendiaries-nav.slick-slider .slick-track {
	display: flex;
	justify-content: center;
}

.home__stipendiaries-nav.slick-slider .slick-slide {
	position: relative;
	width: 13px !important;
	height: 13px;
}

.home__stipendiaries-nav.slick-slider .slick-slide > div {
	height: 13px;
}

.home__stipendiaries-nav.slick-slider .slick-slide + .slick-slide {
	margin-left: 13px;
}

.home__stipendiaries-nav.slick-slider .slick-slide.slick-current .home__slider-count {
	background-color: #ED8C1D;
}

.home__slider-count {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 13px;
	height: 13px;
	cursor: pointer;
	border: none;
	border-radius: 50%;
	background-color: #DDDDDD;
	font-size: 0;
	line-height: 0;
	transition: background-color 0.3s ease-out;
}

.home__slider-count:hover, .home__slider-count:active {
	background-color: #ED8C1D;
}

.home__projects {
	margin-top: 60px;
}

.home__projects .card-image__image img {
	width: 100%;
}

@media (--mobileM) {
	.home__stipendiaries-nav.slick-slider .slick-slide {
		display: none;
	}
	.home__stipendiaries-nav.slick-slider .slick-slide:nth-of-type(1), .home__stipendiaries-nav.slick-slider .slick-slide:nth-of-type(3), .home__stipendiaries-nav.slick-slider .slick-slide:nth-of-type(5) {
		display: block;
	}
}

@media (--tabletS) {
	.home__projects .images-list__content {
		lost-column: 12/12;
		lost-offset: 0/12;
	}
	.home__projects .images-list__wrap .card-image {
		width: 50%;
	}
	.home__projects .images-list__wrap .card-image:nth-of-type(odd) {
		padding: 0 24px 0 0;
	}
	.home__projects .images-list__wrap .card-image:nth-of-type(even) {
		padding: 0 0 0 24px;
	}
	.home__projects .images-list .button {
		min-width: 114px;
	}
	.home__projects .images-list .button__arrow {
		display: none;
	}
	.home__board .images-list__content {
		overflow: visible;
	}
}

@media (--tablet) {
	.home--content {
		padding: 74px 0 46px;
	}
	.home__done-title, .home__stipendiaries-title {
		font-size: 40px;
		line-height: 42px;
	}
	.home__done {
		padding-bottom: 100px;
	}
	.home__done-desc {
		margin-top: 16px;
	}
	.home__done-list {
		lost-column: 10/12;
		lost-offset: 1/12;
		margin-top: 40px;
		display: flex;
		justify-content: space-between;
	}
	.home__done-item {
		padding: 0 16px;
		flex: 1;
	}
	.home__done-item + .home__done-item {
		margin-top: 0;
	}
	.home__done-number {
		padding-top: 38px;
		width: 100%;
		max-width: 200px;
		height: 84px;
		font-size: 28px;
	}
	.home__done-fact {
		margin-top: 20px;
		font-size: 16px;
	}
	.home__banner {
		padding-bottom: 60px;
		overflow: hidden;
	}
	.home__banner-wrap {
		lost-column: 10/12;
		lost-offset: 1/12;
	}
	.home__board {
		margin-bottom: 72px;
	}
	.home__board-link {
		padding-top: 24px;
	}
	.home__board .images-list__content {
		lost-column: 12/12;
		lost-offset: 0/12;
	}
	.home__board .images-list__content:nth-child(n) {
		margin-right: 0 !important;
	}
	.home__board .images-list__content .card-image:nth-of-type(n) {
		padding: 0 30px;
	}
	.home__stipendiaries-box {
		margin-top: 24px;
		padding: 0;
	}
	.home__stipendiaries-slider {
		margin: 0 32px;
	}
	.home__stipendiaries-nav {
		margin-top: 34px;
	}
	.home__stipendiaries-nav.slick-slider .slick-slide:nth-of-type(3), .home__stipendiaries-nav.slick-slider .slick-slide:nth-of-type(5) {
		display: none;
	}
	.home__stipendiaries-nav.slick-slider .slick-slide:nth-of-type(4) {
		display: block;
	}
	.home__projects {
		margin-top: 100px;
	}
	.home__projects .images-list__wrap .card-image {
		width: 25%;
	}
	.home__projects .images-list__wrap .card-image:nth-of-type(n) {
		padding: 0 24px;
	}
	.home__projects .images-list__wrap .card-image:nth-last-of-type {
		padding: 0 0 0 24px;
	}
}

@media (--tabletL) {
	.home__board-list {
		margin: 0 auto;
		width: 830px;
	}
}

@media (--laptop) {
	.home__projects .images-list__wrap .button {
		min-width: 170px;
	}
	.home__projects .images-list__wrap .button__arrow {
		display: inline-block;
	}
	.home__stipendiaries-wrap.wrapper-inner {
		width: 830px;
	}
	.home__stipendiaries-slider {
		margin: 0;
	}
	.home__stipendiaries-slider .slick-slide {
		padding: 0 30px;
	}
	.home__stipendiaries-slider .slick-arrow.slick-prev {
		left: -10px;
	}
	.home__stipendiaries-slider .slick-arrow.slick-next {
		right: -10px;
	}
}

.single-project {
	padding-bottom: 40px;
}

.single-project__about {
	padding: 16px 0 40px;
}

.single-project__image {
	width: 50%;
}

.single-project__image img {
	display: block;
}

.single-project__image + .single-project__text {
	margin-top: 16px;
}

@media (--tablet) {
	.single-project {
		padding-bottom: 48px;
	}
	.single-project__content {
		lost-column: 10/12;
		lost-offset: 1/12;
		overflow: hidden;
	}
	.single-project__about {
		padding: 52px 0 80px;
	}
	.single-project__image {
		lost-column: 4/12;
	}
	.single-project__image + .single-project__text {
		margin-top: 0;
	}
	.single-project__text {
		lost-column: 7/12;
		padding-left: 16px;
	}
}

.stipendiaries {
	padding-bottom: 42px;
}

.stipendiaries__content {
	padding-top: 24px;
}

@media (--mobileS) {
	.stipendiaries__people {
		display: flex;
		flex-wrap: wrap;
	}
	.stipendiaries__people .card-person {
		margin-bottom: 16px;
		width: 50%;
	}
	.stipendiaries__people .card-person:nth-of-type(odd) {
		padding-right: 12px;
	}
	.stipendiaries__people .card-person:nth-of-type(even) {
		padding-left: 12px;
	}
}

@media (--tablet) {
	.stipendiaries {
		padding-bottom: 48px;
	}
	.stipendiaries__content {
		padding-top: 50px;
	}
	.stipendiaries__people .card-person {
		margin-bottom: 54px;
		width: 33%;
	}
	.stipendiaries__people .card-person:nth-of-type(odd), .stipendiaries__people .card-person:nth-of-type(even) {
		padding: 0;
	}
	.stipendiaries__people .card-person:nth-of-type(n) {
		padding: 0 24px;
	}
}

.stipendiary {
	padding: 16px 0 42px;
}

.stipendiary__profile {
	display: flex;
	align-items: center;
}

.stipendiary__image {
	position: relative;
	width: 150px;
	height: 150px;
	overflow: hidden;
}

.stipendiary__image img {
	position: absolute;
	top: 50%;
	left: 50%;
	display: block;
	transform: translate(-50%, -50%);
}

.stipendiary__details {
	padding-left: 24px;
}

.stipendiary__name {
	color: #00529B;
	font-family: "BebasNeue", sans-serif;
	font-size: 28px;
	font-weight: 700;
	line-height: 32px;
}

.stipendiary__faculty {
	color: #4A4A4A;
	font-size: 14px;
	line-height: 24px;
}

.stipendiary__desc {
	margin-top: 8px;
}

.stipendiary__info {
	margin-top: 16px;
}

.stipendiary__info-details {
	display: none;
}

.stipendiary__scholarship {
	margin-top: 24px;
	color: #2A2A2A;
	font-size: 18px;
	font-weight: 700;
	line-height: 24px;
}

.stipendiary__year {
	margin-top: 8px;
}

.stipendiary__year.wyswyg ul,
.stipendiary__year.wyswyg ol {
	margin: 0 0 0 24px;
}

.stipendiary__video {
	margin-top: 48px;
}

.stipendiary__video-image img {
	width: 100%;
	display: block;
}

.stipendiary__video-frame {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	cursor: pointer;
}

.stipendiary__video-frame + .single-members__video-frame {
	margin-top: 24px;
}

.stipendiary__video-frame img {
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
	cursor: pointer;
	transform: translateY(-50%);
}

.stipendiary__video-frame iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.stipendiary__video-frame .play-button {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 80px;
	height: 80px;
	display: block;
	background-image: url("assets/img/svg/icon__play.svg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	transform: translate(-50%, -50%);
}

.stipendiary__donations {
	padding-top: 74px;
}

@media (--tablet) {
	.stipendiary {
		padding: 90px 0 46px;
	}
	.stipendiary__wrap {
		width: 94%;
		margin: 0 auto;
		transition: width .2s ease-in-out;
	}
	.stipendiary__wrap::after {
		clear: both;
		content: "";
		display: table;
	}
}

@media (--tablet) and (--mobileL) {
	.stipendiary__wrap {
		width: 96%;
		margin: 0 auto;
	}
	.stipendiary__wrap::after {
		clear: both;
		content: "";
		display: table;
	}
}

@media (--tablet) and (--tablet) {
	.stipendiary__wrap {
		width: 96%;
		margin: 0 auto;
	}
	.stipendiary__wrap::after {
		clear: both;
		content: "";
		display: table;
	}
}

@media (--tablet) and (--laptop) {
	.stipendiary__wrap {
		width: 1024px;
		margin: 0 auto;
	}
	.stipendiary__wrap::after {
		clear: both;
		content: "";
		display: table;
	}
}

@media (--tablet) {
	.stipendiary__content {
		lost-column: 12/12;
		overflow: hidden;
	}
	.stipendiary__profile {
		lost-column: 4/12;
		lost-offset: 1/12;
	}
	.stipendiary__details {
		display: none;
	}
	.stipendiary__name {
		font-size: 40px;
		line-height: 42px;
	}
	.stipendiary__desc {
		margin-top: 16px;
	}
	.stipendiary__info {
		lost-column: 7/12;
		margin-top: 0;
	}
	.stipendiary__info-details {
		display: block;
	}
	.stipendiary__image {
		width: 240px;
		height: 240px;
	}
	.stipendiary__scholarship {
		font-size: 24px;
		line-height: 32px;
	}
	.stipendiary__video {
		margin-top: 104px;
	}
	.stipendiary__donations {
		padding-top: 96px;
	}
}

.button {
	position: relative;
	padding: 12px 30px;
	min-width: 240px;
	border: none;
	border-radius: 33px;
	overflow: hidden;
	display: inline-block;
	background-color: transparent;
	color: #FFFFFF;
	font-size: 14px;
	letter-spacing: 2px;
	line-height: 20px;
	text-align: center;
	text-transform: uppercase;
}

.button:before, .button:after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -1;
	width: 100%;
	border-radius: 33px;
	content: "";
	transition: all 0.3s;
}

.button:before {
	z-index: -1;
	background-color: #ED8C1D;
	transform: translateX(-100%);
}

.button:after {
	z-index: -2;
	background-color: #028ED5;
}

.button:hover, .button:active {
	background-color: transparent;
}

.button:hover:before, .button:active:before {
	transform: translateX(0%);
}

.button--tiny {
	padding: 8px;
	min-width: 170px;
	font-size: 10px;
	font-weight: 700;
}

.button--donate {
	padding: 12px 24px;
	min-width: 160px;
	font-size: 18px;
	font-weight: 700;
}

.button--donate:before {
	background-color: #028ED5;
}

.button--donate:after {
	background-color: #ED8C1D;
}

.button__arrow {
	position: relative;
	top: 2px;
}

.button__arrow svg {
	width: 10px;
	height: 10px;
}

.card-person__details {
	position: relative;
}

.card-person__link {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	font-size: 0;
	line-height: 0;
}

.card-person__info {
	position: relative;
	overflow: hidden;
}

.card-person__image {
	padding-bottom: 100%;
	width: 100%;
	height: 0;
	overflow: hidden;
	filter: gray;
	/* IE5+ */
	-webkit-filter: grayscale(1);
	/* Webkit Nightlies & Chrome Canary */
	transition: all .5s ease-out;
}

.card-person__image img {
	width: 100%;
	display: block;
}

.card-person__image--none {
	background-color: #F6F6F6;
}

.card-person__box {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	backface-visibility: hidden;
	transform: translate3D(0, 0, 0);
	transition: transform 0.3s ease-out;
	-webkit-font-smoothing: antialiased;
}

.card-person__name {
	position: relative;
	z-index: 1;
	padding: 10px;
	color: #00529B;
	font-family: "BebasNeue", sans-serif;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
}

.card-person__name:after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -1;
	background-color: #FFFFFF;
	content: '';
	opacity: 0.88;
}

.card-person__faculty {
	margin-top: 10px;
	color: #4A4A4A;
	font-size: 14px;
	font-weight: 700;
	line-height: 18px;
}

@media (--tablet) {
	.card-person__name {
		padding: 20px;
		font-size: 16px;
		line-height: 24px;
	}
	.card-person__link:hover + .card-person__info .card-person__box, .card-person__link:active + .card-person__info .card-person__box {
		transform: perspective(1px) translate3d(0, 0, 0);
	}
	.card-person__link:hover + .card-person__info .card-person__image, .card-person__link:active + .card-person__info .card-person__image {
		filter: none;
		-webkit-filter: grayscale(0);
	}
	.card-person__box {
		transform: translate3D(0, 100%, 0);
	}
}

.card-image {
	position: relative;
}

.card-image--contributor .card-image__title {
	font-size: 14px;
	line-height: 18px;
}

.card-image__link {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
}

.card-image__box {
	position: relative;
	overflow: hidden;
}

.card-image__image {
	position: relative;
	width: 100%;
	height: 0;
	overflow: hidden;
	padding-bottom: 100%;
	filter: gray;
	/* IE5+ */
	-webkit-filter: grayscale(1);
	/* Webkit Nightlies & Chrome Canary */
	transition: all .5s ease-out;
}

.card-image__image--none {
	background-color: #F6F6F6;
}

.card-image__image img {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: block;
}

.card-image__button {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 100%);
	transition: transform 0.3s ease-out;
}

.card-image__title {
	margin-top: 8px;
	color: #4A4A4A;
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
}

@media (--tablet) {
	.card-image--contributor .card-image__box:after {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 8px;
		border-radius: 0 4px 4px 0;
		background-color: #ED8C1D;
		content: '';
		transform: translateX(-100%);
		transition: transform 0.3s ease-out;
	}
	.card-image--contributor .card-image__title {
		margin-top: 8px;
	}
	.card-image--contributor .card-image__link:hover + .card-image__info .card-image__box:after, .card-image--contributor .card-image__link:active + .card-image__info .card-image__box:after {
		transform: translateX(0);
	}
	.card-image__link:hover + .card-image__info .card-image__image, .card-image__link:active + .card-image__info .card-image__image {
		filter: none;
		-webkit-filter: grayscale(0);
	}
	.card-image__link:hover + .card-image__info .card-image__button, .card-image__link:active + .card-image__info .card-image__button {
		transform: translate(-50%, -16px);
	}
	.card-image__title {
		margin-top: 16px;
	}
}

.collect-donations__title {
	margin-bottom: 32px;
	color: #00529B;
	font-family: "BebasNeue", sans-serif;
	font-size: 28px;
	font-weight: 700;
	line-height: 32px;
	text-align: center;
}

.collect-donations__item {
	text-align: center;
}

.collect-donations__item + .collect-donations__item {
	margin-top: 18px;
}

.collect-donations__icon {
	margin-bottom: 10px;
}

.collect-donations__icon img {
	display: inline-block;
}

.collect-donations__subtitle {
	color: #4A4A4A;
	font-size: 14px;
	font-weight: 700;
	line-height: 24px;
	text-transform: uppercase;
}

@media (--tablet) {
	.collect-donations__title {
		margin-bottom: 48px;
		font-size: 40px;
		line-height: 48px;
	}
	.collect-donations__list {
		display: flex;
		justify-content: space-between;
	}
	.collect-donations__item {
		padding: 0 16px;
		flex: 1;
	}
	.collect-donations__item + .collect-donations__item {
		margin-top: 0;
	}
	.collect-donations__icon {
		margin-bottom: 28px;
	}
	.collect-donations__subtitle {
		font-size: 16px;
	}
}

.cta-build {
	position: relative;
	margin-bottom: 40px;
	padding: 24px 32px;
	border-radius: 25px 25px 25px 0;
	background-color: #F6F6F6;
	text-align: center;
}

.cta-build:after {
	position: absolute;
	left: 0;
	bottom: -40px;
	z-index: -1;
	border-top: 40px solid transparent;
	border-bottom: 40px solid transparent;
	border-left: 70px solid #F6F6F6;
	content: '';
}

.cta-build__link {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	outline: none;
}

.cta-build__title {
	color: #4A4A4A;
	font-family: "BebasNeue", sans-serif;
	font-size: 22px;
	font-weight: 700;
	letter-spacing: 2px;
	line-height: 40px;
}

.cta-build__image {
	margin-top: 16px;
	margin-left: 16px;
	flex: 1;
}

.cta-build__image svg {
	width: 100%;
	max-height: 40px;
}

@media (--tablet) {
	.cta-build {
		padding: 28px 32px;
	}
	.cta-build__content {
		margin: 0 auto;
		max-width: 520px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.cta-build__title {
		font-size: 30px;
		line-height: 40px;
	}
	.cta-build__image {
		margin-top: 0;
	}
}

.cta-donate {
	position: relative;
	margin-bottom: 40px;
	padding: 24px 32px;
	border-radius: 25px 25px 25px 0;
	background-color: #F6F6F6;
	text-align: center;
}

.cta-donate:after {
	position: absolute;
	left: 0;
	bottom: -40px;
	z-index: -1;
	border-top: 40px solid transparent;
	border-bottom: 40px solid transparent;
	border-left: 70px solid #F6F6F6;
	content: '';
}

.cta-donate__content {
	position: relative;
	z-index: 1;
}

.cta-donate__title {
	color: #4A4A4A;
	font-family: "BebasNeue", sans-serif;
	font-size: 22px;
	font-weight: 700;
	letter-spacing: 2px;
	line-height: 40px;
}

.cta-donate__btn {
	margin-top: 16px;
}

@media (--tablet) {
	.cta-donate {
		padding: 28px 32px;
	}
	.cta-donate__content {
		margin: 0 auto;
		max-width: 520px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.cta-donate__title {
		font-size: 30px;
		line-height: 40px;
	}
	.cta-donate__btn {
		margin-top: 0;
	}
}

.header {
	position: relative;
	z-index: 100;
	overflow: hidden;
	background-color: #028ED5;
	background-image: url("assets/img/lu-building.jpg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.header:before, .header:after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-repeat: no-repeat;
	background-size: contain;
	content: '';
	transform: scale(1);
	transition: transform 0.3s ease-out;
}

.header:before {
	background-image: url("assets/img/flag-us.png");
	background-position: left center;
}

.header:after {
	background-image: url("assets/img/flag-lv.png");
	background-position: right center;
}

.header--active:before, .header--active:after {
	transform: scale(1.6);
}

.header--active .header__bg {
	opacity: 0.7;
}

.header--active .header__lang-list {
	display: flex;
}

.header--active .header__motto {
	display: none;
}

.header__bg {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	background-color: #028ED5;
	opacity: 0;
	transition: opacity 0.3s ease-out;
}

.header__wrap {
	position: relative;
	z-index: 10;
	padding: 24px 32px;
}

.header__content {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.header__logo {
	display: inline-block;
	font-size: 0;
	line-height: 0;
}

.header__logo-link {
	position: relative;
	z-index: 1;
	width: 84px;
	height: 60px;
	display: inline-block;
	font-size: 0;
	line-height: 0;
}

.header__logo svg {
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	width: 84px;
	height: 60px;
}

.header__menus {
	position: relative;
	z-index: 1;
	display: flex;
	align-items: center;
}

.header__menus .menu-item a {
	color: #FFFFFF;
	font-family: "BebasNeue", sans-serif;
}

.header__menu-list .menu-item {
	font-size: 24px;
	line-height: 32px;
}

.header__menu-list .menu-item a {
	position: relative;
	overflow: hidden;
	display: inline-block;
}

.header__menu-list .menu-item a:after {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 1px;
	background-color: #FFFFFF;
	content: '';
	transform: translateX(-100%);
	transition: transform 0.3s ease-out;
}

.header__menu-list .menu-item:hover a:after, .header__menu-list .menu-item.current-lang a:after {
	transform: translateX(0);
}

.header__lang-list {
	margin-right: 48px;
	display: flex;
}

.header__lang-list .menu-item {
	width: 25px;
	height: 25px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	background-color: transparent;
	justify-content: center;
	transition: background-color 0.3s ease-out;
}

.header__lang-list .menu-item a {
	font-size: 15px;
	line-height: 18px;
}

.header__lang-list .menu-item:hover, .header__lang-list .menu-item.current-lang {
	background-color: #FFFFFF;
}

.header__lang-list .menu-item:hover a, .header__lang-list .menu-item.current-lang a {
	color: #028ED5;
}

.header__lang-list .menu-item.current-lang {
	pointer-events: none;
}

.header__lang-list .menu-item + .menu-item {
	margin-left: 20px;
}

.header__menu-list, .header__lang-list {
	display: none;
}

.header__burger {
	position: absolute;
	top: 50%;
	right: 0;
	width: 100%;
	height: 6px;
	border-radius: 2px;
	background-color: #FFFFFF;
	opacity: 1;
	transform: translateY(-50%);
	transition: all .3s ease-in-out;
}

.header__burger:before, .header__burger:after {
	position: absolute;
	width: 38px;
	height: 6px;
	border-radius: 2px;
	display: block;
	background-color: #FFFFFF;
	content: "";
	transition: all .3s ease-in-out;
}

.header__burger:before {
	transform: translateY(12px);
}

.header__burger:after {
	transform: translateY(-12px);
}

.header__burger-box {
	position: absolute;
	top: 50%;
	width: 38px;
	height: 16px;
	display: inline-block;
	transform: translateY(-50%);
}

.header__burger-button {
	position: relative;
	width: 38px;
	height: 34px;
	cursor: pointer;
}

.header__burger--open .header__burger {
	background-color: transparent;
}

.header__burger--open .header__burger:before {
	top: 0;
	transform: rotate(45deg) translateY(0);
}

.header__burger--open .header__burger:after {
	bottom: 0;
	transform: rotate(-45deg) translateY(0);
}

.header__drop {
	position: relative;
	z-index: 100;
	overflow: hidden;
	max-height: 0;
	transition: max-height 0.3s ease-out;
}

.header__drop--active {
	max-height: 1000px;
}

.header__drop-content {
	height: calc(100vh - 108px);
	display: flex;
	align-items: center;
	justify-content: center;
}

.header__drop .menu-item {
	text-align: center;
}

.header__drop .menu-item a {
	position: relative;
	padding: 4px 16px;
	overflow: hidden;
	display: inline-block;
	color: #FFFFFF;
	font-family: "BebasNeue", sans-serif;
	font-size: 40px;
	font-weight: 700;
	line-height: 44px;
}

.header__drop .menu-item a:after {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 1px;
	background-color: #FFFFFF;
	content: '';
	transform: translateX(-100%);
	transition: transform 0.3s ease-out;
}

.header__drop .menu-item:hover a:after, .header__drop .menu-item.current-lang a:after {
	transform: translateX(0);
}

.header__drop .menu-item + .menu-item {
	margin-top: 18px;
}

.header__motto {
	padding: 100px 24px 174px;
}

.header__motto p {
	color: #FFFFFF;
	font-family: "BebasNeue", sans-serif;
	font-size: 40px;
	font-weight: 700;
	line-height: 46px;
	text-align: center;
}

@media (--tablet) {
	.header:before, .header:after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-size: contain;
	}
	.header__wrap {
		width: 94%;
		margin: 0 auto;
		transition: width .2s ease-in-out;
		padding: 28px 16px;
	}
	.header__wrap::after {
		clear: both;
		content: "";
		display: table;
	}
}

@media (--tablet) and (--mobileL) {
	.header__wrap {
		width: 96%;
		margin: 0 auto;
	}
	.header__wrap::after {
		clear: both;
		content: "";
		display: table;
	}
}

@media (--tablet) and (--tablet) {
	.header__wrap {
		width: 96%;
		margin: 0 auto;
	}
	.header__wrap::after {
		clear: both;
		content: "";
		display: table;
	}
}

@media (--tablet) and (--laptop) {
	.header__wrap {
		width: 1024px;
		margin: 0 auto;
	}
	.header__wrap::after {
		clear: both;
		content: "";
		display: table;
	}
}

@media (--tablet) {
	.header__logo-link,
	.header__logo svg {
		width: 124px;
		height: 80px;
	}
	.header__menu-list, .header__lang-list {
		display: flex;
	}
	.header__menu-list {
		margin-right: 80px;
	}
	.header__menu-list .menu-item + .menu-item {
		margin-left: 42px;
	}
	.header__lang-list {
		margin-right: 0;
	}
	.header__lang-list .menu-item {
		width: 30px;
		height: 30px;
	}
	.header__lang-list .menu-item a {
		font-size: 20px;
		line-height: 24px;
	}
	.header__burger-button {
		display: none;
	}
	.header__drop {
		display: none;
	}
	.header__motto {
		padding: 96px 32px 194px;
	}
	.header__motto p {
		font-size: 62px;
		line-height: 70px;
	}
}

.footer {
	background-color: #00529B;
}

.footer__content {
	position: relative;
	padding: 28px 0 38px;
}

.footer__content:before {
	position: absolute;
	top: 0;
	left: 0;
	width: 50px;
	height: 100px;
	display: block;
	background-image: url("assets/img/footer-logo.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	content: "";
}

.footer__title {
	margin-left: 54px;
	color: #FFFFFF;
	font-size: 14px;
	line-height: 24px;
	text-transform: uppercase;
}

.footer__title span {
	display: block;
	font-weight: 600;
}

.footer__list {
	margin-top: 40px;
}

.footer__item {
	position: relative;
	margin-left: 54px;
	min-height: 75px;
	display: flex;
	align-items: center;
}

.footer__item-icon {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.footer__item--phone .footer__item-icon {
	left: -40px;
}

.footer__item--email .footer__item-icon {
	left: -52px;
}

.footer__item--web .footer__item-icon {
	left: -53px;
}

.footer__item a {
	color: #FFFFFF;
	font-size: 16px;
	font-weight: 600;
	line-height: 32px;
}

.footer__item + .footer__item {
	margin-top: 16px;
}

@media (--tablet) {
	.footer__list {
		display: flex;
		justify-content: space-between;
	}
	.footer__item--phone .footer__item-content {
		min-height: 64px;
	}
	.footer__item + .footer__item {
		margin-top: 0;
	}
}

@media (--laptop) {
	.footer__content:before {
		width: 88px;
		height: 178px;
	}
	.footer__title {
		font-size: 16px;
	}
	.footer__title span {
		display: inline-block;
	}
	.footer__title, .footer__list {
		margin-left: 156px;
	}
	.footer__item {
		margin-left: 70px;
	}
	.footer__item--phone {
		margin-left: 56px;
		letter-spacing: 2px;
	}
	.footer__item--phone .footer__item-icon {
		left: -56px;
	}
	.footer__item--email .footer__item-icon {
		left: -68px;
	}
	.footer__item--web .footer__item-icon {
		left: -69px;
	}
}

.images-list {
	margin-bottom: 54px;
}

.images-list__wrap {
	position: relative;
}

.images-list__wrap:after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -1;
	background-color: #F6F6F6;
	opacity: 0.5;
	content: '';
}

.images-list__title {
	padding: 0 24px 24px;
	color: #00529B;
	font-family: "BebasNeue", sans-serif;
	font-size: 28px;
	font-weight: 700;
	line-height: 40px;
	text-align: center;
}

.images-list__content {
	position: relative;
	display: flex;
	flex-wrap: wrap;
}

.images-list__content .card-image {
	margin-bottom: 24px;
	width: 50%;
}

.images-list__content .card-image:nth-of-type(even) {
	padding-left: 8px;
}

.images-list__content .card-image:nth-of-type(odd) {
	padding-right: 8px;
}

@media (--tabletS) {
	.images-list {
		margin-bottom: 96px;
	}
	.images-list__content .card-image {
		width: 33.33%;
	}
	.images-list__content .card-image:nth-of-type(n) {
		padding: 0 24px;
	}
}

@media (--tablet) {
	.images-list__content {
		lost-column: 10/12;
		lost-offset: 1/12;
		overflow: hidden;
	}
	.images-list__title {
		padding-bottom: 32px;
		font-size: 40px;
		line-height: 48px;
	}
}

@media (--laptop) {
	.images-list__content .card-image:nth-of-type(n) {
		padding: 0 35px;
	}
}

/* fixes for specific browser bugs */
.ios .cta-donate__title {
	font-weight: 400;
}

.ios .button.button--donate {
	font-weight: 400;
}
