.single-project {
  padding-bottom: 40px;

  &__about {
    padding: 16px 0 40px;
  }

  &__image {
    width: 50%;

    img {
      display: block;
    }

    & + .single-project__text {
      margin-top: 16px;
    }
  }

  &__projects {

  }

  @media (--tablet) {
    padding-bottom: 48px;

    &__content {
      lost-column: 10/12;
      lost-offset: 1/12;
      overflow: hidden;
    }

    &__about {
      padding: 52px 0 80px;
    }

    &__image {
      lost-column: 4/12;

      & + .single-project__text {
        margin-top: 0;
      }
    }

    &__text {
      lost-column: 7/12;
      padding-left: 16px;
    }
  }
}