h1, h2, h3, h4, h5, h6 {
	font-family: $font-primary;
	color: $color-stone;
}

h1, h2, h3 {
	text-transform: uppercase;
}

h1 {
	font-size: 41px;
	line-height: 48px;
}

h2 {
	font-size: 28px;
	line-height: 32px;
}

h3 {
	font-size: 14px;
	line-height: 24px;
}

h3, h4, h5, h6 {
	font-weight: 700;
}

h4, h5 {
	font-size: 18px;
	line-height: 24px;
}

h5 {
	font-family: $font-secondary;

	&+ h4 {
		margin-top: 24px;
	}
}

h6 {
	font-size: 14px;
	line-height: 20px;
}

div, p, ul {
	&+ h1 {
		margin-top: 32px;
	}

	&+ h2,
	&+ h3,
	&+ h4 {
		margin-top: 16px;
	}

	&+ h5 {
		margin-top: 24px;
	}
}

@media (--laptop) {
	h1 {
		font-size: 62px;
		line-height: 64px;
	}

	h2 {
		font-size: 40px;
		line-height: 48px;
	}

	h3 {
		font-size: 16px;
	}

	h4,	h5 {
		font-size: 24px;
		line-height: 32px;
	}

	h6 {
		font-size: 16px;
	}

	div, p, ul {
		&+ h1 {
			margin-top: 56px;
		}

		&+ h2 {
			margin-top: 16px;
		}

		&+ h4 {
			margin-top: 40px;
		}
	}
}