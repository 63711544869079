.images-list {
  margin-bottom: 54px;

  &__wrap {
    position: relative;

    &:after {
      @include absolute-position;
      z-index: -1;
      background-color: $color-snow;
      opacity: 0.5;
      content: ''
    }
  }

  &__title {
    padding: 0 24px 24px;
    color: $color-primary;
    font-family: $font-secondary;
    font-size: 28px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
  }

  &__content{
    position: relative;
    display: flex;
    flex-wrap: wrap;

    .card-image {
      margin-bottom: 24px;
      width: 50%;

      &:nth-of-type(even) {
        padding-left: 8px;
      }

      &:nth-of-type(odd) {
        padding-right: 8px;
      }
    }
  }

  @media (--tabletS) {
    margin-bottom: 96px;

    &__content {
      .card-image {
        width: 33.33%;

        &:nth-of-type(n) {
          padding: 0 24px;
        }
      }
    }
  }

  @media (--tablet) {
    &__content {
      lost-column: 10/12;
      lost-offset: 1/12;
      overflow: hidden;
    }

    &__title {
      padding-bottom: 32px;
      font-size: 40px;
      line-height: 48px;
    }
  }

  @media (--laptop) {
    &__content {
      .card-image {
        &:nth-of-type(n) {
          padding: 0 35px;
        }
      }
    }
  }
}