.card-image {
  position: relative;

  &--contributor {
    .card-image {
      &__title {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  &__link {
    @include absolute-position;
    z-index: 1;
  }

  &__box {
    position: relative;
    overflow: hidden;
  }

  &__image {
    position: relative;
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-bottom: 100%;
    filter: gray; /* IE5+ */
    -webkit-filter: grayscale(1); /* Webkit Nightlies & Chrome Canary */
    transition: all .5s ease-out;

    &--none {
      background-color: $color-snow;
    }

    img {
      @include absolute-position;
      display: block;
    }
  }

  &__button {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%,100%);
    transition: transform 0.3s ease-out;
  }

  &__title {
    margin-top: 8px;
    color: $color-stone;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }

  @media (--tablet) {
    &--contributor {
      .card-image {
        &__box {
           &:after {
             position: absolute;
             bottom: 0;
             left: 0;
             width: 100%;
             height: 8px;
             border-radius: 0 4px 4px 0;
             background-color: $color-sand;
             content: '';
             transform: translateX(-100%);
             transition: transform 0.3s ease-out;
           }
        }

        &__title {
          margin-top: 8px;
        }

        &__link {
          &:hover,
          &:active {
            & + .card-image__info {
              .card-image__box {
                &:after {
                  transform: translateX(0);
                }
              }
            }
          }
        }
      }
    }

    &__link {
      &:hover,
      &:active {
        & + .card-image__info {
          .card-image {
            &__image {
              filter: none;
              -webkit-filter: grayscale(0);
            }

            &__button {
              transform: translate(-50%,-16px);
            }
          }
        }
      }
    }

    &__title {
      margin-top: 16px;
    }
  }
}