.home {
  &--content {
    padding: 28px 0 42px;
  }

  &__done-title,
  &__stipendiaries-title {
    color: $color-primary;
    font-family: $font-secondary;
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
  }

  &__done {
    padding-bottom: 56px;

    &-desc {
      margin-top: 8px;
      color: $color-stone;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    }

    &-list {
      margin-top: 36px;
    }

    &-item {
      text-align: center;

      & + .home__done-item {
        margin-top: 40px;
      }
    }

    &-number {
      position: relative;
      padding-top: 28px;
      width: 148px;
      height: 64px;
      display: inline-block;
      background-image: url("assets/img/lu-building.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      color: $color-primary;
      font-size: 22px;
      font-weight: 700;
      text-align: center;
    }

    &-fact {
      margin-top: 12px;
      color: $color-stone;
      font-size: 14px;
      font-weight: 700;
      line-height: 24px;
      text-transform: uppercase;
    }
  }

  &__banner {
    padding-bottom: 60px;
  }

  &__board {
    margin-bottom: 60px;
    overflow: hidden;

    &-list {
      .wrapper {
        width: 100%;
      }
    }

    & > .wrapper {
      position: relative;

      &:after {
        position: absolute;
        top: 60px;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -3;
        width: 100%;
        height: 100%;
        display: block;
        background-color: $color-steel;
        background-image: url("assets/img/svg/bg-pattern.svg");
        background-position: center;
        background-repeat: repeat;
        background-size: contain;
        content: '';
      }
    }

    &-link {
      background-color: $color-white;
      text-align: center;

      .button {
        z-index: 1;
      }
    }

    .images-list {
      margin-bottom: 0;

      &__wrap {
        &:after {
          display: none;
        }
      }

      &__content {
        .card-image {
          margin-bottom: 0;

          &__info {
            height: 100%;
            display: flex;
            flex-direction: column;
          }

          &__title {
            position: relative;
            margin-top: 0;
            padding: 8px 0 24px;
            flex: 1;

            &:after {
              position: absolute;
              top: 0;
              left: 50%;
              z-index: -1;
              width: 300%;
              height: 100%;
              display: block;
              background-color: $color-white;
              content: '';
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }

  &__stipendiaries {
    &-box {
      margin-top: 16px;
      padding: 0 28px;
    }

    &-link {
      margin-top: 26px;
      text-align: center;
    }

    &-slider {
      .slick-list {
        padding-top: 16px;
        border-right: 1px solid $color-secondary;
        border-left: 1px solid $color-secondary;
      }

      .slick-slide {
        padding: 0 12px;
      }

      .slick-arrow {
        position: absolute;
        top: 50%;
        margin: 0;
        padding: 0;
        width: 26px;
        height: 30px;
        border: none;
        background-color: transparent;
        background-image: url("assets/img/svg/icon__arrow-blue.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        cursor: pointer;
        font-size: 0;
        line-height: 0;
        outline: none ;

        &.slick-next {
          right: 0;
          transform: translate(28px, -50%) rotate(0deg);
        }

        &.slick-prev {
          left: 0;
          transform: translate(-28px, -50%) rotate(180deg);
        }
      }
    }

    &-nav {
      margin-top: 16px;

      &.slick-slider {
        .slick-track {
          display: flex;
          justify-content: center;
        }

        .slick-slide {
          position: relative;
          width: 13px !important;
          height: 13px;

          & > div {
            height: 13px;
          }

          & + .slick-slide {
            margin-left: 13px;
          }

          &.slick-current {
            .home__slider-count {
              background-color: $color-sand;
            }
          }
        }
      }
    }
  }

  &__slider {
    &-count {
      @include absolute-position;
      width: 13px;
      height: 13px;
      cursor: pointer;
      border: none;
      border-radius: 50%;
      background-color: $color-silver;
      font-size: 0;
      line-height: 0;
      transition: background-color 0.3s ease-out;

      &:hover,
      &:active {
        background-color: $color-sand;
      }
    }
  }

  &__projects {
    margin-top: 60px;

    .card-image__image img {
      width: 100%;
    }
  }

  @media (--mobileM) {
    &__stipendiaries {
      &-nav {
        &.slick-slider {
          .slick-slide {
            display: none;

            &:nth-of-type(1),
            &:nth-of-type(3),
            &:nth-of-type(5) {
              display: block;
            }
          }
        }
      }
    }
  }

  @media (--tabletS) {
    &__projects {
      .images-list {
        &__content {
          lost-column: 12/12;
          lost-offset: 0/12;
        }

        &__wrap {
          .card-image {
            width: 50%;

            &:nth-of-type(odd) {
              padding: 0 24px 0 0;
            }

            &:nth-of-type(even) {
              padding: 0 0 0 24px;
            }
          }
        }

        .button {
          min-width: 114px;

          &__arrow {
            display: none;
          }
        }
      }
    }

    &__board {
      .images-list__content {
        overflow: visible;
      }
    }
  }

  @media (--tablet) {
    &--content {
      padding: 74px 0 46px;
    }

    &__done-title,
    &__stipendiaries-title {
      font-size: 40px;
      line-height: 42px;
    }

    &__done {
      padding-bottom: 100px;

      &-desc {
        margin-top: 16px;
      }

      &-list {
        lost-column: 10/12;
        lost-offset: 1/12;
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
      }

      &-item {
        padding: 0 16px;
        flex: 1;

        & + .home__done-item {
          margin-top: 0;
        }
      }

      &-number {
        padding-top: 38px;
        width: 100%;
        max-width: 200px;
        height: 84px;
        font-size: 28px;
      }

      &-fact {
        margin-top: 20px;
        font-size: 16px;
      }
    }

    &__banner {
      padding-bottom: 60px;
      overflow: hidden;

      &-wrap {
        lost-column: 10/12;
        lost-offset: 1/12;
      }
    }

    &__board {
      margin-bottom: 72px;

      &-link {
        padding-top:  24px;
      }

      .images-list {
        &__content {
          lost-column: 12/12;
          lost-offset: 0/12;

          &:nth-child(n) {
            margin-right: 0 !important;
          }

          .card-image:nth-of-type(n) {
            padding: 0 30px;
          }
        }
      }
    }

    &__stipendiaries {
      &-box {
        margin-top: 24px;
        padding: 0;
      }

      &-slider {
       margin: 0 32px;
      }

      &-nav {
        margin-top: 34px;

        &.slick-slider {
          .slick-slide {
            &:nth-of-type(3),
            &:nth-of-type(5) {
              display: none;
            }

            &:nth-of-type(4) {
              display: block;
            }
          }
        }
      }
    }

    &__projects {
      margin-top: 100px;

      .images-list__wrap {
        .card-image {
          width: 25%;

          &:nth-of-type(n) {
            padding: 0 24px;
          }

          &:nth-last-of-type {
            padding: 0 0 0 24px;
          }
        }
      }
    }
  }

  @media (--tabletL) {
    &__board {
      &-list {
        margin: 0 auto;
        width: 830px;
      }
    }
  }

  @media (--laptop) {
    &__projects {
      .images-list__wrap {
        .button {
          min-width: 170px;

          &__arrow {
            display: inline-block;
          }
        }
      }
    }

    &__stipendiaries {
      &-wrap {
        &.wrapper-inner {
          width: 830px;
        }
      }

      &-slider {
        margin: 0;

        .slick-slide {
          padding: 0 30px;
        }

        .slick-arrow {
          &.slick-prev {
            left: -10px;
          }

          &.slick-next {
            right: -10px;
          }
        }
      }
    }
  }
}