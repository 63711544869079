$color-primary: #00529B;
$color-secondary: #028ED5;

$color-black: #000000;
$color-default: #2A2A2A;
$color-metal: #8D8D8D;
$color-sand: #ED8C1D;
$color-snow: #F6F6F6;
$color-silver: #DDDDDD;
$color-steel: #FAFAFA;
$color-stone: #4A4A4A;
$color-white: #FFFFFF;
//$color-dark: #343436;
//$color-grey:#919195;
//$color-fire: #D0021B;
//$color-light: #F1F1F1;
//$color-ocean: #0069AA;
//$color-ocean--deep: #004168;
//$color-orange: #F26631;
//$color-silver: #E9EAEB;
//$color-sky : #A6BEDE;
//$color-snow: #F4F5F5;
//$color-steel: #CCCCCC;
//$color-stone: #2D292A;
//$color-sun: #FFF200;
//$color-white: #FFFFFF;