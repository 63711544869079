.wyswyg__table {
	padding-top: 16px;
	margin-bottom: 24px;
	overflow-x: auto;
}

table {
	table-layout: fixed;
}

thead {
	th {
		border-bottom: 3px solid $color-primary;
	}
}

thead,
tfoot {
	th,
	td {
		padding: 0 0 8px;
		color: $color-primary;
		font-weight: 600;
		text-align: left;
	}
}

tbody {
	th,
	td {
		border-bottom: 1px solid $color-primary;

		&+ th,
		&+ td {
			border-left: 1px solid $color-primary;
		}
	}

	tr {
		&:last-of-type {
			td {
				border-bottom: 3px solid $color-primary;
			}
		}
	}
}

th,
td {
	padding: 16px 16px 16px 0;
	min-width: 188px;
	font-size: 16px;
	color: $color-default;
	line-height: 24px;

	&+ th,
	&+ td {
		padding: 16px 16px 16px 10px;
	}
}

tr {
	background-color: transparent;
	transition: background-color 0.2s linear;

	&:hover {
		background-color: #f7f7f7;
	}
}

@media (--laptop) {
	thead,
	tfoot {
		th,
		td {
			font-size: 22px;
		}
	}

	th,
	td {
		padding: 26px 26px 26px 0;
		font-size: 20px;
		letter-spacing: 0.4px;
		line-height: 30px;

		&+ th,
		&+ td {
			padding: 26px 26px 26px 22px;
		}
	}
}