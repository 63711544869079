$font-primary: 'Open Sans', sans-serif;
$font-secondary: 'BebasNeue', sans-serif;
$font-playfair: 'Play Fair', sans-serif;

@font-face {
  font-family: 'Open Sans';
  src: url('assets/fonts/openSans/OpenSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('assets/fonts/openSans/OpenSans-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('assets/fonts/openSans/OpenSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('assets/fonts/openSans/OpenSans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('assets/fonts/openSans/OpenSans-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('assets/fonts/openSans/OpenSans-ExtraBold.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'BebasNeue';
  src: url('assets/fonts/bebasNeue/bebasneue-regular-webfont.woff2') format('woff2'),
       url('assets/fonts/bebasNeue/bebasneue-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BebasNeue';
  src: url('assets/fonts/bebasNeue/bebasneue-bold-webfont.woff2') format('woff2'),
       url('assets/fonts/bebasNeue/bebasneue-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Play Fair';
  src: url('assets/fonts/playFair/PlayfairDisplay-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}