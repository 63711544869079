.authors {
  padding-bottom: 58px;

  &__qoute {
    padding-top: 32px;

    &__wrap {
      overflow: hidden;
    }

    &-main,
    &-author {
      color: $color-stone;
      font-family: $font-playfair;
    }

    &-main {
      font-size: 20px;
      line-height: 32px;
      text-align: center;
    }

    &-author {
      position: relative;
      margin-top: 16px;
      padding: 0 12px;
      display: inline-block;
      float: right;
      font-size: 16px;
      line-height: 24px;

      &:before,
      &:after {
        position: absolute;
        top: 50%;
        width: 1px;
        height: 50%;
        display: inline-block;
        background-color: $color-stone;
        content: '';
        transform: translateY(-50%) skewX(-25deg);
      }

      &:before {
        left: 0;
      }

      &:after {
        right: 0;
      }
    }
  }

  &__about {
    padding-top: 34px;
  }

  @media (--tablet) {
    &__wrap {
      lost-column: 8/12;
      lost-offset: 2/12;
    }

    &__author {
      text-align: center;
    }
  }
}