$ctaBuildArrowSize: 40px;
.cta-build {
  position: relative;
  margin-bottom: $ctaBuildArrowSize;
  padding: 24px 32px;
  border-radius: 25px 25px 25px 0;
  background-color: $color-snow;
  text-align: center;

  &:after {
    position: absolute;
    left: 0;
    bottom: -$ctaBuildArrowSize;
    z-index: -1;
    border-top: $ctaBuildArrowSize solid transparent;
    border-bottom: $ctaBuildArrowSize solid transparent;
    border-left: 70px solid $color-snow;
    content: '';
  }

  &__link {
    @include absolute-position;
    outline: none;
  }

  &__title {
    color: $color-stone;
    font-family: $font-secondary;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 2px;
    line-height: 40px;
  }

  &__image {
    margin-top: 16px;
    margin-left: 16px;
    flex: 1;

    svg {
      width: 100%;
      max-height: 40px;
    }
  }

  @media (--tablet) {
    padding: 28px 32px;

    &__content {
      margin: 0 auto;
      max-width: 520px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__title {
      font-size: 30px;
      line-height: 40px;
    }

    &__image {
      margin-top: 0;
    }
  }
}