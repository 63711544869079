.card-person {
  &__details {
    position: relative;
  }

  &__link {
    @include absolute-position;
    z-index: 1;
    font-size: 0;
    line-height: 0;
  }

  &__info {
    position: relative;
    overflow: hidden;
  }

  &__image {
    padding-bottom: 100%;
    width: 100%;
    height: 0;
    overflow: hidden;
    filter: gray; /* IE5+ */
    -webkit-filter: grayscale(1); /* Webkit Nightlies & Chrome Canary */
    transition: all .5s ease-out;

    img {
      width: 100%;
      display: block;
    }

    &--none {
      background-color: $color-snow;
    }
  }

  &__box {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    backface-visibility: hidden;
    transform: translate3D(0,0,0);
    transition: transform 0.3s ease-out;
    -webkit-font-smoothing: antialiased;
  }

  &__name {
    position: relative;
    z-index: 1;
    padding: 10px;
    color: $color-primary;
    font-family: $font-secondary;
    font-size: 14px;
    line-height: 18px;
    text-align: center;

    &:after {
      @include absolute-position;
      z-index: -1;
      background-color: $color-white;
      content: '';
      opacity: 0.88;
    }
  }

  &__faculty {
    margin-top: 10px;
    color: $color-stone;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
  }

  @media (--tablet) {
    &__name {
      padding: 20px;
      font-size: 16px;
      line-height: 24px;
    }

    &__link {
      &:hover,
      &:active {
        & + .card-person__info {
          .card-person {
            &__box {
              transform: perspective(1px) translate3d(0,0,0);
            }

            &__image {
              filter: none;
              -webkit-filter: grayscale(0);
            }
          }
        }
      }
    }

    &__box {
      transform: translate3D(0,100%,0);
    }
  }
}