.custom-give {
  .give-form {
    text-align: center;
  }

  .give-sum-text {
    margin-bottom: 16px;
    color: $color-stone;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    text-transform: uppercase;
  }

  .give-form-title {
    margin-bottom: 16px;
    color: $color-stone;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
  }

  .give-goal-progress {
    margin-bottom: 32px;
    color: $color-metal;
    font-size: 14px;
    line-height: 16px;

    .raised {
      margin-bottom: 18px;
    }

    .income {
      margin-right: 8px;
      color: $color-primary;
      font-family: $font-secondary;
      font-size: 30px;
      font-weight: 700;
      line-height: 40px;
    }
  }
  .give-donation-expenses {
    margin-bottom: 16px;
    display: none;
  }

  .give_error,
  .give_success {
    margin: 15px;
  }

  .give-btn {
    position: relative;
    padding: 16px 30px;
    min-width: 154px;
    border: none;
    border-radius: 33px;
    overflow: hidden;
    background-color: transparent;
    color: $color-white;
    font-size: 18px;
    letter-spacing: 2px;
    line-height: 20px;
    text-transform: uppercase;

    &:before,
    &:after {
      @include absolute-position;
      z-index: -1;
      width: 100%;
      border-radius: 33px;
      content: "";
      transition: all 0.3s;
    }

    &:before {
      z-index: -1;
      background-color: $color-secondary;
      transform: translateX(-100%);
    }

    &:after {
      z-index: -2;
      background-color: $color-sand;
    }

    &:hover,
    &:active {
      background-color: transparent;

      &:before {
        transform: translateX(0%);
      }
    }
  }

  form[id*="give-form"] {
    &.give-display-modal .give-btn,
    &.give-display-reveal .give-btn,
    .give-btn-modal {
      margin: 0;
    }

    .give-donation-amount {
      margin-bottom: 22px;
      display: inline-block;

      #give-amount {
        padding: 4px 12px;
        border-radius: 7px 0 0 7px;

        &:focus {
          box-shadow: 0 1px 6px 0 rgba(2,142,213,0.32);

          & +  .give-currency-symbol.give-currency-position-after {
            box-shadow: 0 1px 6px 0 rgba(2,142,213,0.32);

            &:before {
              display: block;
            }
          }
        }
      }

      .give-currency-symbol.give-currency-position-after {
        position: relative;
        padding: 4px 12px 4px 0;
        border-radius: 0 7px 7px 0;

        &:before {
          position: absolute;
          top: 0;
          left: -6px;
          bottom: 0;
          display: none;
          width: 6px;
          background-color: $color-snow;
          content: '';
        }
      }

      #give-amount,
      .give-currency-symbol.give-currency-position-after {
        height: initial;
        border: none;
        background-color: $color-snow;
        color: $color-stone;
        font-family: $font-secondary;
        font-size: 30px;
        font-weight: 700;
        line-height: 40px;
      }
    }
  }

  @media (--tablet) {
    .give-form-title {
      font-size: 16px;
    }

    .give-sum-text {
      font-size: 16px;
    }
  }
}

.give-append-link {
  margin: 16px auto 0;
  max-width: 466px;
  text-align: center;

  a {
    color: $color-metal;
    font-size: 14px;
    line-height: 20px;
    text-decoration: underline;

    &:hover {
      color: $color-stone;
    }
  }
}


body {
  .give_error,
  .give_success,
  .give_warning {
    margin: 15px 0 15px 15px;
  }
}


/* give modal style */
.give-modal {
  .mfp-close {
    font-size: 44px;
  }

  .mfp-container {
    .mfp-content {
      padding: 36px 10px 48px;
      width: 100%;
      max-width: 830px;
    }
  }

  form[id*="give-form"] {
    #give_checkout_user_info legend,
    .give-payment-mode-label {
      border: none;
      color: $color-primary;
      font-family: $font-secondary;
      font-size: 26px;
      line-height: 32px;
      text-align: center;
    }

    #give_purchase_form_wrap {
      padding-top: 24px;
    }

    #give_checkout_user_info legend {
      margin-top: 0;
    }

    #give-payment-mode-select {
      .give-loading-text {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 10;
        height: 32px;
        width: 30px;
        font-size: 30px;
        transform: translateX(-50%, -50%);
      }

      .give-loading-animation {
        height: 32px;
        width: 30px;
        font-size: 30px;
      }
    }

    #give-gateway-radio-list {
      li {
        position: relative;
        margin-right: 0;
        width: 100%;
        display: block;
        background-color: $color-snow;

        & + li {
          margin-top: 8px;
        }
      }

      .give-gateway {
        position: absolute;
        top: 50%;
        left: 8px;
        z-index: -1;
        width: 10px;
        height: 10px;
        opacity: 0;
        transform: translateY(-50%);

        &:checked {
          & + .give-gateway-option {
            &:before {
              background-color: $color-sand;
            }
          }
        }
      }

      .give-gateway-option {
        padding: 12px 14px 12px 48px;
        width: 100%;
        display: block;
        color: $color-stone;
        font-size: 14px;
        line-height: 24px;
        text-transform: uppercase;

        &:before {
          position: absolute;
          top: 50%;
          left: 8px;
          width: 25px;
          height: 25px;
          border: 2px solid $color-sand;
          border-radius: 50%;
          content: '';
          background-color: transparent;
          transform: translateY(-50%);
          transition: background-color 0.3s ease-out;
        }
      }
    }

    .give-select-wrap {
      .selectric .label {
        height: 42px;
        font-size: 16px;
        line-height: 42px;
      }
    }

    #give_checkout_user_info {
      .form-row {
        position: relative;

        input {
          padding: 0 10px;
          height: 44px;
          border-radius: 3px;
          line-height: 44px;
        }
      }

      .give-label {
        opacity: 0;
        visibility: hidden;
        display: block;
        position: absolute;
        top: -12px;
        left: 0;
        font-size: 12px;
        font-weight: 700;
        line-height: 1;
        transition: all .2s ease-in-out;
        z-index: 1;
      }
    }

    #give-final-total-wrap {
      .give-donation-total-label,
      .give-final-total-amount {
        width: 100%;
        height: initial;
        border: none;
        display: block;
        background-color: transparent;
        color: $color-stone;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
      }
    }

    .give-submit-button-wrap {
      display: flex;
      justify-content: center;

      .give-btn {
        position: relative;
        margin: 0 auto;
        padding: 16px 30px;
        min-width: 154px;
        border: none;
        border-radius: 33px;
        overflow: hidden;
        display: inline-block;
        background-color: $color-sand;
        color: $color-white;
        font-size: 18px;
        letter-spacing: 2px;
        line-height: 20px;
        text-transform: uppercase;
        transition: all 0.3s;
        //&:before,
        //&:after {
        //  @include absolute-position;
        //  z-index: -1;
        //  width: 100%;
        //  border-radius: 33px;
        //  content: "";
        //  transition: all 0.3s;
        //}
        //
        //&:before {
        //  z-index: -1;
        //  background-color: $color-secondary;
        //  transform: translateX(-100%);
        //}
        //
        //&:after {
        //  z-index: -2;
        //  background-color: $color-sand;
        //}

        &:hover,
        &:active {
          background-color: $color-secondary;
        }
      }
    }
  }

  @media ( --mobileXL) {
    form[id*="give-form"] {
      #give_checkout_user_info {
        .form-row {
          input {
            font-size: 16px;
          }
        }
      }
    }
  }

  @media (--tablet) {
    .mfp-container {
      .mfp-content {
        padding: 60px 96px 40px;
      }
    }

    form[id*="give-form"] {
      #give_purchase_form_wrap {
        margin-top: 16px;
        border-top: 1px solid $color-silver;
      }

      #give_checkout_user_info legend,
      .give-payment-mode-label {
        font-size: 40px;
        line-height: 42px;
      }

      #give-gateway-radio-list {
        display: flex;
        flex-wrap: wrap;

        li {
          margin-bottom: 8px;
          width: 48%;
          width: calc(50% - 4px);

          &:nth-of-type(even) {
            margin-left: 4px;
          }

          &:nth-of-type(odd) {
            margin-right: 4px;
          }

          & + li {
            margin-top: 0;
          }
        }
      }

      #give-final-total-wrap {
        margin-top: 50px;
      }

      .give-select-wrap {
        width: 48.5%;
        float: right;
      }

      #give_checkout_user_info {
        #give-email-wrap {
          width: 48.5%;
          float: left;
        }
      }
    }
  }
}