.not-found {
  &__content {
    margin-top: 162px;
    text-align: center;
  }

  &__error {
    color: $color-sand;
    font-size: 86px;
    font-weight: 900;
    line-height: 86px;
  }

  &__title,
  &__subtitle {
    color: $color-stone;
    font-size: 16px;
    line-height: 24px;
  }

  &__title {
    margin-top: 24px;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__subtitle {
    margin-top: 16px;
  }

  &__btn {
    margin-top: 24px;
  }

  @media (--tablet) {
    &__content {
      lost-column: 6/12;
      lost-offset: 3/12;
      margin-top: 240px;
    }

    &__error {
      font-size: 124px;
      line-height: 124px;
    }

    &__title {
      margin-top: 32px;
      font-size: 22px;
      line-height: 32px;
    }

    &__btn {
      margin-top: 32px;
    }
  }
}